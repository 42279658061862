import { useEffect, useState } from "react";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
// import { IoIosRemoveCircle } from "react-icons/io";
import { MapPinMinusInside } from 'lucide-react';
import { useMapContext } from "../../Hooks/mapContext";
import { Card } from "../RealStateInvestmentAi/RealStateInvestmentAiNew";
// A reusable DynamicList component that accepts any JSX element as children
export default function DynamicList({ items, setItemLength }) {
  const { removeItem } = useMapContext();
  const [listItems, setListItems] = useState(items);
  useEffect(() => {
    setListItems([...items]);
  }, [items]);
  useEffect(() => {
    console.log(removeItem);
    // if (!isNaN(removeItem)) {
    let arr = [...listItems];
    arr.splice(removeItem, 1);
    setListItems(arr);
    // }
  }, [removeItem]);
  const handleRemove = (index) => {
    const newItems = listItems.filter((_, i) => i !== index);
    setListItems(newItems);
  };
  useEffect(() => {
    setItemLength(listItems.length);
  }, [listItems.length]);
  return (
    <div className="dynamic-list mx-2 ">
      {listItems.length > 0 && (
        <h3 className="text-right font-bold text-[20px]">الأراضي المحددة</h3>
      )}

      <AnimatePresence>
        {listItems.map((item, index) => (
          <ItemWrapper key={index} onRemove={() => handleRemove(index)}>
            <Card data={item.data} />
          </ItemWrapper>
        ))}
      </AnimatePresence>
    </div>
  );
}

// Item wrapper component to handle animations
const ItemWrapper = ({ children, onRemove }) => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40 },
  };
  return (
    <motion.div {...animations} layout>
      <div className="relative">
        <div onClick={onRemove} className="absolute left-2  cursor-pointer hover:scale-110 duration-300 top-5">
          <img src="/cancellation.webp" className="object-cover" alt="" />
        </div>
        {children}
      </div>
    </motion.div>
  );
};
