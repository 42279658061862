import Header from "../components/Header";
import { useLang } from "../../Hooks/LangContext";
import { useEffect, useState, useMemo } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { LangText } from "../../language/LangElements";
import { MdOutlineClear } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import MyMap from "../components/maps";
import { MapSearch } from "../components/mapSearch";
import { useMapContext } from "../../Hooks/mapContext";
import requestInvestment, { getImagePrompt } from "../../apis/pdfApi";
import { useNavigate } from "react-router-dom";
import DynamicList from "../components/list";
// import List from "../components/list";
import {
  FaMapMarkerAlt,
  FaRulerCombined,
  FaBuilding,
  FaCalendarAlt,
} from "react-icons/fa";
import { MapPin, Building2 } from "lucide-react";
import RecommendationsChart, {
  AnalysisUI,
} from "../components/charts/mapChart";
import { usePopup } from "../../Hooks/popup";
import LandAnalysis from "../components/charts/mapChart";
import { useAuth } from "../../Hooks/authContext";
import StudyCasesApi from "../../apis/StudyCases";
const endpoints = {
  "مبنى سكني": "/ar/investment-residential-building",
  "مبنى تجاري سكني": "/ar/investment-residential-commercial-building",
  "مبنى تجاري": "/ar/investment-commercial-building",
  "مول تجاري": "/ar/investment-shopping-mall",
  فلل: "/ar/investment-villas",
  فيلا: "/ar/investment-villa",
  "مجمع سكني": "/ar/investment-residential-compound",
  "مبنى إداري": "/ar/investment-administrative-building",
  فندقى: "/ar/investment-hotel",
  "برج تجاري سكني": "/ar/investment_Commercial_residential_tower",
  "برج تجاري إداري": "/ar/investment_Commercial_and_administrative_tower",
  "برج إداري": "/ar/investment_administrative_tower",
};
const apiKey = "AIzaSyC3LhU5__i5drYp2kZkZ5su50khcxh3sFU"; // Replace with your actual API key
export default function RealStateInvestmentAiNew() {
  const [choose, setChoose] = useState(null);

  return (
    <div>
      <Header />
      <div className="w-full bg-white min-h-screen py-2 flex justify-center items-center relative bg-cover bg-no-repeat  ">
        {choose === null && <LandingPage setChoose={setChoose} />}
      </div>
    </div>
  );
}

// function ChooseLandOrOwnership({ setChoose }) {
//   const { lang } = useLang();
//   return (
//     <div className="bg-white rounded-2xl w-[350px] md:w-[600px] text-center p-10">
//       <p className="font-bold md:text-[22px] mb-10 ">
//         {lang === "ar"
//           ? "الاستثمار العقاري الذكاء الاصطناعي"
//           : "Real Estate Investment Artificial Intelligence"}
//       </p>
//       <div className="flex justify-center gap-[45px] [&>div]:p-2 [&>div]:rounded-lg [&>div]:w-[25%] [&>div]:flex [&>div]:justify-center [&>div]:items-center [&>div]:text-[20px] [&>div]:font-semibold [&>div]:text-white [&>div]:bg-[#79a9fd]">
//         <div
//           onClick={() => {
//             setChoose("ownership");
//           }}
//           className="cursor-pointer"
//         >
//           {lang === "ar" ? "ملكيه" : "ownership"}
//         </div>
//         <div
//           onClick={() => {
//             setChoose("land");
//           }}
//           className="cursor-pointer"
//         >
//           {lang === "ar" ? "ارض" : "land"}
//         </div>
//       </div>
//     </div>
//   );
// }
export async function generateImg(prompt) {
  let request = {
    prompt: prompt,
  };
  let response = await fetch(
    `https://coral-app-8z265.ondigitalocean.app/image-model-2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }
  );
  let imageObject = await response.json();
  return {
    url: imageObject.data.data[0].url,
    prompt: imageObject.data.data[0].prompt,
  };
}

function LandingPage({ setChoose }) {
  const { setLang, lang } = useLang("ar");
  const { selectedPositionData, mapPhotoData, items, searchData } =
    useMapContext();
  const { photo } = useMapContext();
  const { auth } = useAuth();

  let finalDataForBackend = {
    title: "",
    data: "",
    mapImage: "",
    images: [],
  };
  const [landing, setLanding] = useState({
    district: "",
    area: "",
    PropertyType: "مبنى سكني",
    City: "الرياض",
    neighborhood: "",
    landArea: "",
    landPrice: "",
    areaId: "",
    customPropertyType: "",
    commercialFloors: "",
    residentialFloors: "",
    administrativeFloors: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(null);
  const navigate = useNavigate();
  const [stage, setStage] = useState(0);

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    setLanding((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!landing.neighborhood) {
      newErrors.district =
        lang === "ar" ? "الحيّ مطلوب" : "district is required";
    }
    if (!landing.landArea) {
      newErrors.area = lang === "ar" ? "المساحه مطلوب" : "area is required";
    }
    return newErrors;
  };
  const getNoOfAppartmentsFromText = (text) => {
    const regex = /\d+/; // Regular expression to find one or more digits
    const match = text.match(regex); // Execute the regular expression search
    return match ? parseInt(match[0], 10) : null; // Return the number if found, otherwise return null
  };
  const getProposedFloors = (jsonData) => {
    return jsonData["تقرير_تحليل_الاستثمار"]["معايير_التطوير"][
      "الطوابق_المقترحة"
    ];
  };

  // Function to get the value of "عدد الشقق في كل طابق"
  const getNumberOfApartmentsPerFloor = (jsonData) => {
    return jsonData["تقرير_تحليل_الاستثمار"]["معايير_التطوير"][
      "الطوابق_المقترحة"
    ];
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data;
    const validationErrors = validate();
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   return;
    // }

    const buildPayload = () => {
      const type =
        landing?.PropertyType === "أخرى"
          ? landing?.customPropertyType
          : landing?.PropertyType;
      let baseInput = `City: ${landing?.City}, Property Type: ${type}, Land Area: ${landing?.area}, Neighborhood: ${landing?.neighborhood}`;
      if (landing?.PropertyType === "برج تجاري سكني") {
        baseInput += `, number of commercial floors is: ${landing?.commercialFloors}, and the number of residential floors is: ${landing?.residentialFloors}`;
      } else if (landing?.PropertyType === "برج تجاري إداري") {
        baseInput += `, number of commercial floors is: ${landing?.commercialFloors}, and the number of administrative floors is: ${landing?.administrativeFloors}`;
      }

      return {
        input: `${baseInput}`,
        price: `${landing?.landPrice}`,
        images: [photo],
      };
    };

    const payload = buildPayload();

    try {
      setIsSubmitting(true);
      const response = await fetch(
        `https://coral-app-8z265.ondigitalocean.app${
          endpoints[landing?.PropertyType] || endpoints["مبنى سكني"]
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
      const d = await response.json();
      data = d;
      finalDataForBackend.data = data;
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setIsSubmitting(false);
    }

    let images = [];
    let prompts = [];

    const proposedFloors = getProposedFloors(data);
    const apartmentsPerFloor =
      getNoOfAppartmentsFromText(getNumberOfApartmentsPerFloor(data)) /
      proposedFloors;
    try {
      setStage(1);
      let generalImagePrompt = getImagePrompt({
        type: landing?.PropertyType.replace("مبنى", ""),
        floors: proposedFloors || 5,
        appartments: apartmentsPerFloor || 2,
        proposedFloors,
        apartmentsPerFloor,
        ...landing,
      });
      console.log(generalImagePrompt);
      // return;
      let [img1, img2, img3] = await Promise.all([
        generateImg(
          `4 realistic photos from different angles of ${generalImagePrompt} 4 photos is a must!!`
        ),
        generateImg(`A photo of interior design for ${generalImagePrompt}`),
        generateImg(
          `4 realistic interior photos for ${
            landing?.PropertyType === "أخرى"
              ? landing?.customPropertyType
              : landing?.PropertyType
          } 4 photos is a must!!`
        ),
      ]);
      images.push(img1.url, img2.url, img3.url);
      prompts.push(img1.prompt, img2.prompt, img3.prompt);
      console.log(prompts, images, "prompts");
      // setFinalDataForBackend({
      // 	...finalDataForBackend,
      // 	images: [...images, images],
      // });
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSubmitting(false);
    setStage(0);
    // const { mapPhotoData } = useMapContext();
    // const canvas = document.querySelector(".mapboxgl-canvas");
    const createStudy = (images, photo, data) => {
      finalDataForBackend = {
        data: data,
        title: data["العنوان"],
        images: [...images],
        prompts: [...prompts],
        mapImage: photo,
      };
      console.log(finalDataForBackend);

      StudyCasesApi.createStudyCase(finalDataForBackend, auth)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      // if (x) {
      navigate("/convert", {
        state: {
          apiResponse: data,
          mapUrl: null,
          images: images,
          prompts,
          blockProps: { blockId: landing?.blockId, landId: landing?.areaId },
        },
      });
      // }
      // console.log("finalDataForBackend", finalDataForBackend);
    };
    createStudy(images, photo, data);
  };
  useEffect(() => {
    const canvas = document.querySelector(".mapboxgl-canvas");
    console.log(canvas.toDataURL());
  }, []);
  useEffect(() => {
    setLanding((prev) => ({
      ...prev,
      neighborhood:
        selectedPositionData?.neighborh_aname ||
        selectedPositionData?.neighborhaname,
      landArea: selectedPositionData?.shape_area?.toFixed(0),
      landPrice: selectedPositionData?.price_of_meter,
      PropertyType: selectedPositionData?.landuseagroup,
      blockId: selectedPositionData?.parcel_no,
      areaId: selectedPositionData?.subdivision_no,
      city: "الرياض",
    }));
  }, [selectedPositionData]);

  const propertyTypes = useMemo(() => {
    const arabicToEnglish = {
      "مبنى سكني": "Residential Building",
      "مبنى تجاري سكني": "Mixed-use Building",
      "مبنى تجاري": "Commercial Building",
      "مول تجاري": "Shopping Mall",
      فلل: "Villas",
      فيلا: "Villa",
      "مجمع سكني": "Residential Compound",
      "مبنى إداري": "Administrative Building",
      فندقى: "hotel",
      "برج تجاري سكني": "Residential Commercial Tower",
      "برج تجاري إداري": "Commercial Administrative Tower",
      "برج إداري": "Administrative Tower",
    };

    return lang === "en"
      ? Object.keys(endpoints).map((key) => arabicToEnglish[key] || "Other")
      : Object.keys(endpoints);
  }, [lang]);

  const cities = useMemo(() => {
    return lang === "en"
      ? [
          "Riyadh",
          "Jeddah",
          "Mecca",
          "Medina",
          "Dammam",
          "Dubai",
          "Sharjah",
          "Abu Dhabi",
        ]
      : [
          "الرياض",
          "جدة",
          "مكة",
          "المدينة",
          "الدمام",
          "دبي",
          "الشارقة",
          "أبو ظبي",
        ];
  }, [lang]);
  const [searchMethode, setSearchMethode] = useState("address");
  function handleToggleSearchMeathode() {
    if (searchMethode === "address") {
      setSearchMethode("numberplans");
    } else {
      setSearchMethode("address");
    }
  }
  console.log(searchMethode);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    let area = items.reduce(
      (total, current) => total + current?.data?.shape_area,
      0
    );
    setLanding((prev) => ({
      ...prev,
      area: area,
    }));
  }, [items]);
  const { popup } = usePopup();
  const Suggestions = () => {
    popup.run(
      <>
        <LandAnalysis lang={lang} photo={photo} />
      </>
    );
  };
  const [itemLength, setItemLength] = useState(0);
  const [createStudyCheckInter, setCreateStudyCheckInter] = useState(false);
  return (
    <div className=" rounded-2xl container mx-auto py-2">
      <div>
        <Search
          isMobile={isMobile}
          handleToggleSearchMeathode={handleToggleSearchMeathode}
          searchMethode={searchMethode}
          setSearchMethode={setSearchMethode}
        />
        <div className="flex md:flex-row flex-col-reverse [&>*]:md:w-1/2">
          <div className="md:h-[650px] h-[450px] w-full mx-auto rounded-[10px] overflow-hidden md:w-1/2 rounded-b-[20px] mt-20 mr-10">
            <MyMap />
          </div>
          <form
            // border: 1px solid
            onSubmit={handleSubmit}
            className="w-full  md:w-1/2 gap-10 flex flex-col justify-center items-center py-24"
          >
            {/* <h2 className="font-bold text-[25px] md:text-[25px] mb-10 text-center">
              {lang === "ar"
                ? "الاستثمار العقاري بالذكاء الاصطناعي"
                : "Real Estate Investment Artificial Intelligence"}
            </h2> */}
            <div
              className={`${
                itemLength === 0 && "hidden"
              }  px-3 border-[#868686] rounded-[20px]  ${
                items && "border border-[#868686]"
              }`}
            >
              {console.log(itemLength === 0)}
              <div
                className={`max-h-[400px] w-[410px] 
               overflow-hidden
               py-5 my-2
               [&::-webkit-scrollbar]:w-1
               [&::-webkit-scrollbar-track]:rounded-full
               [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:bg-gray-300
               overflow-y-auto`}
              >
                <DynamicList items={items} setItemLength={setItemLength} />
              </div>
            </div>
            <div className="border p-5 w-[420px] rounded-[20px] border-[#868686]">
              <ul
                className="flex flex-col font-semibold my-5 w-[90%] mx-auto gap-5
                 [&>li>label]:block
                [&>li>label]:mb-1"
              >
                {/* <li>
                <label htmlFor="district">
                  <LangText ar="حيّ" en="district" />
                </label>
                <input
                  onChange={handleSignInChange}
                  type="text"
                  id="district"
                  name="district"
                  value={landing.neighborhood}
                  required
                  placeholder={lang === "ar" ? "حيّ" : "district"}
                />
                {errors.district && (
                  <p
                    className={`text-red-500 text-[12px] ${
                      lang === "ar" ? "text-left" : "text-right"
                    }`}
                  >
                    {errors.district}
                  </p>
                )}
              </li> */}
                {/* <li>
                <label htmlFor="area">
                  <LangText
                    ar="مساحه الارض بالمتر المربع"
                    en="Land area in square meters"
                  />
                </label>
                <input
                  onChange={handleSignInChange}
                  type="number"
                  id="area"
                  name="area"
                  value={
                    selectedPositionData?.shape_area?.toFixed(0) || landing.area
                  }
                  required
                  placeholder={lang === "ar" ? "مساحه" : "area"}
                />
                {errors.area && (
                  <p
                    className={`text-red-500 text-[12px] ${
                      lang === "ar" ? "text-left" : "text-right"
                    }`}
                  >
                    {errors.area}
                  </p>
                )}
              </li> */}
                <li>
                  <label className="font-bold text-[20px]" htmlFor="area">
                    <LangText ar="مساحة الأرض م²" en="Land area m²" />
                  </label>
                  <input
                    onChange={handleSignInChange}
                    type="number"
                    id="area"
                    name="area"
                    value={landing.area}
                    required
                    className="
                    [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                    placeholder={lang === "ar" ? "مساحه" : "area"}
                  />
                  {errors.area && (
                    <p
                      className={`text-red-500 text-[12px] ${
                        lang === "ar" ? "text-left" : "text-right"
                      }`}
                    >
                      {errors.area}
                    </p>
                  )}
                </li>
                <li>
                  <label
                    className="font-bold text-[20px]"
                    htmlFor="PropertyType"
                  >
                    <LangText ar="نوع العقار" en="Property Type" />
                  </label>
                  <select
                    dir="rtl"
                    name="PropertyType"
                    placeholder="PropertyType"
                    className="
                    
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px] "
                    id="PropertyType"
                    value={landing?.PropertyType}
                    onChange={handleSignInChange}
                  >
                    <option disabled selected hidden value="">
                      {lang === "ar" ? "اختر العقار" : "Select the property"}
                    </option>

                    {!propertyTypes.includes(landing?.PropertyType) && (
                      <span>{landing?.PropertyType}</span>
                    )}
                    {propertyTypes.map((e) => (
                      <option
                        selected={e.includes(landing?.PropertyType)}
                        key={e}
                        value={e}
                      >
                        {e}
                      </option>
                    ))}
                  </select>
                </li>
                {(landing.PropertyType === "other" ||
                  landing.PropertyType === "أخرى") && (
                  <li>
                    <input
                      onChange={handleSignInChange}
                      type="text"
                      id="customPropertyType"
                      name="customPropertyType"
                      placeholder={
                        lang === "ar" ? "نوع العقار" : "Custom Property Type"
                      }
                    />
                  </li>
                )}
                {landing.PropertyType === "برج تجاري سكني" && (
                  <li>
                    <label htmlFor="PropertyType">
                      <LangText
                        ar="عدد الطوابق التجارية"
                        en="commercial floors"
                      />
                    </label>
                    <input
                      onChange={handleSignInChange}
                      type="number"
                      id="customPropertyType"
                      name="commercialFloors"
                      className="
                      [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                      placeholder={
                        lang === "ar"
                          ? "عدد الطوابق التجارية"
                          : "Number of commercial floors"
                      }
                    />
                  </li>
                )}
                {landing.PropertyType === "برج تجاري سكني" && (
                  <li>
                    <label
                      className="font-bold text-[20px]"
                      htmlFor="PropertyType"
                    >
                      <LangText
                        ar="عدد الطوابق السكنية"
                        en="residential  floors"
                      />
                    </label>
                    <input
                      onChange={handleSignInChange}
                      type="number"
                      id="customPropertyType"
                      name="residentialFloors"
                      className="
                      [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                      placeholder={
                        lang === "ar"
                          ? "عدد الطوابق السكنية"
                          : "Number of residential floors"
                      }
                    />
                  </li>
                )}
                {landing.PropertyType === "برج تجاري إداري" && (
                  <li>
                    <label
                      className="font-bold text-[20px]"
                      htmlFor="PropertyType"
                    >
                      <LangText
                        ar="عدد الطوابق التجارية"
                        en="commercial floors"
                      />
                    </label>
                    <input
                      onChange={handleSignInChange}
                      type="number"
                      id="customPropertyType"
                      name="commercialFloors"
                      className="
                      [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                      placeholder={
                        lang === "ar"
                          ? "عدد الطوابق التجارية"
                          : "Number of commercial floors"
                      }
                    />
                  </li>
                )}
                {landing.PropertyType === "برج تجاري إداري" && (
                  <li>
                    <label
                      className="font-bold text-[20px]"
                      htmlFor="PropertyType"
                    >
                      <LangText
                        ar="عدد الطوابق الإدارية"
                        en="admisitrative floors"
                      />
                    </label>
                    <input
                      onChange={handleSignInChange}
                      type="number"
                      id="customPropertyType"
                      name="administrativeFloors"
                      className="
                      [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                      placeholder={
                        lang === "ar"
                          ? "عدد الطوابق الإدارية"
                          : "Number of administrative floors"
                      }
                    />
                  </li>
                )}
                <li>
                  <label className="font-bold text-[20px]" htmlFor="City">
                    <LangText ar="المدينة" en="City" />
                  </label>
                  <select
                    dir="rtl"
                    required
                    name="City"
                    id="City"
                    value={landing.City}
                    onChange={handleSignInChange}
                    className="
                    
                    focus:outline-none w-full p-2
                    text-right bg-[#F0F0F0] rounded-[22px]"
                  >
                    <option disabled selected hidden value="">
                      {lang === "ar" ? "اختر المدينه" : "Choose the city"}
                    </option>
                    {cities.map((e) => (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    ))}
                  </select>
                </li>
              </ul>
              <button
                disabled={isSubmitting || itemLength < 1}
                style={
                  isSubmitting || itemLength < 1
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
                onPointerEnter={() => {
                  setCreateStudyCheckInter(true);
                }}
                onPointerOut={() => {
                  setCreateStudyCheckInter(false);
                }}
                className="
                hover:bg-white duration-300 mb-5 hover:text-[#9d9dff] hover:border-[#9d9dff] bg-[#9d9dff] flex justify-center items-center flex-row gap-2 w-[200px] mt-[5px] text-[20px] font-bold text-white mx-auto p-2 rounded-[25px] focus:outline-none border
                "
              >
                {createStudyCheckInter
                  ? itemLength < 1
                    ? lang === "ar"
                      ? "اختر من الخيرطه"
                      : "choose from map"
                    : lang === "ar"
                    ? "انشاء الدراسه"
                    : "create study"
                  : lang === "ar"
                  ? "انشاء الدراسه"
                  : "create study"}

                {isSubmitting && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#9d9dff]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
              <button
                type="button"
                // disabled={isSubmitting}
                onClick={Suggestions}
                className="
                hover:bg-white duration-300 hover:text-[#9d9dff] hover:border-[#9d9dff] bg-[#9d9dff] flex justify-center items-center flex-row gap-2 w-[200px] mt-[5px] text-[20px] font-bold text-white mx-auto p-2 rounded-[25px] focus:outline-none border
                "
              >
                {lang === "ar" ? "إقتراحات" : "Suggestions"}{" "}
              </button>
            </div>
            {isSubmitting && stage === 0 && (
              <>{lang === "ar" ? `...يتم انشاء دراسة` : `creating study...`}</>
            )}
            {isSubmitting && stage === 1 && (
              <>
                {lang === "ar"
                  ? `يتم انشاء صور افتراضية `
                  : `Virtual images are creating`}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

function Search({
  // setChoose,
  isMobile,
  handleToggleSearchMeathode,
  searchMethode,
  setSearchMethode,
}) {
  const { lang } = useLang();
  return (
    <div className="  w-full  items-center border border-[#868686] rounded-[20px] flex md:flex-row flex-col py-3 px-8 justify-between">
      <div className="flex justify-between">
        {/* <div
          onClick={() => {
            setChoose(null);
          }}
          className="cursor-pointer bg-black flex items-center gap-1 text-[#9995ee] font-semibold"
        ></div> */}
        {/* {isMobile && (
          <div className="flex  gap-2 items-center">
            <div
              onClick={handleToggleSearchMeathode}
              className="cursor-pointer bg-[#f0f0f0] relative gap-1 rounded-lg  items-center flex"
            >
              <span
                className={`text-nowrap ${
                  searchMethode !== "address" && "text-white"
                }   duration-150 px-2 relative z-10  `}
              >
                {lang === "ar" ? "رقم المخطط" : "Scheme number"}
              </span>
              <div
                className={`text-nowrap ${
                  searchMethode === "address" && "text-white"
                }   duration-150 p-2 relative z-10 rounded-lg `}
              >
                {lang === "ar" ? "العنوان" : "Address"}
              </div>
              <div
                className={`${
                  searchMethode === "address"
                    ? "w-[70px] right-0"
                    : lang === "ar"
                    ? "w-[100px] left-0"
                    : "w-[130px] left-0"
                }  duration-150 bg-[#9d9dff] z-0 top-0  h-full  rounded-lg absolute`}
              ></div>
            </div>
            <p className="text-nowrap text-[14px]">
              {lang === "ar" ? "ابحث بواسطه" : "search by"}
            </p>
          </div>
        )} */}
      </div>
      <MapSearch
        isMobile={isMobile}
        setSearchMethode={setSearchMethode}
        searchMethode={searchMethode}
      />
    </div>
  );
}

export const Card = ({ title, content, data }) => {
  return (
    <div className=" p-4 border my-5  border-[#CFCFCF] rounded-[20px] shadow-lg max-w-md mx-auto">
      <h2 className="text-xl font-bold text-right mb-4">
        {data?.neighborhaname} بالرياض قطعة رقم {data?.parcel_no}
      </h2>

      <div className=" text-black flex items-center mb-2 flex-row-reverse">
        <MapPin className="text-black w-5 h-5 ml-2" />
        <p className="font-bold text-right">
          الموقع: حي {data?.neighborhaname}، الرياض
        </p>
      </div>

      <div className="flex items-center text-black mb-2 flex-row-reverse">
        <div className="w-5 h-5 ml-2">
          <img src="/areaicon.webp" className="object-cover" alt="" />
        </div>
        <p className="font-bold text-right">
          المساحة: {data?.shape_area.toFixed(1)} م²
        </p>
      </div>

      <div className="flex items-center text-black mb-2 flex-row-reverse">
        <Building2 className=" w-5 h-5 ml-2" />
        <p className="font-bold text-right">
          نوع المشروع: {data?.landuseadetailed || data?.landuseagroup}
        </p>
      </div>
    </div>
  );
  // return (
  //   <div style={{ border: "1px solid black", padding: "20px", margin: "10px" }}>
  //     <h2>{data?.landuseadetailed}</h2>
  //     <p>{data?.neighborhaname}</p>
  //   </div>
  // );
};
