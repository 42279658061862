export default function GridentBorders({
  className,
  children,
  rounded,
  strockWidth,
  gridentStyle,
  resetColor,
  onClick,
}) {
  return (
    <section
      onClick={onClick}
      style={{ padding: strockWidth }}
      className={`${className} relative    flex justify-center items-center  `}
    >
      {resetColor ? (
        <div
          style={{ borderRadius: `${rounded}` }}
          className={` w-full h-full flex justify-center items-center ${resetColor}`}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}

      <div
        style={{
          borderRadius: `${rounded}`,
          backgroundImage: `linear-gradient(${gridentStyle})`,
        }}
        className=" w-full   -z-10 h-full absolute"
      />
    </section>
  );
}
