import axios from "axios";
import { useEffect, useState } from "react";
import { useMapContext } from "../Hooks/mapContext";
import { usePopup } from "../Hooks/popup";
import { generateImg } from "./RealStateInvestmentAi/RealStateInvestmentAiNew";
// import "./pdf.css";
const fetchImages = async (imageUrls, setImagesReal) => {
  try {
    const imagePromises = imageUrls.map((imageUrl) => {
      return axios.post(
        "https://shark-app-thdw4.ondigitalocean.app/fetch-image/",
        { imageUrl }
      );
    });

    // Resolve all image fetch promise
    const responses = await Promise.all(imagePromises);

    // Extract base64 strings from each response
    const base64Images = responses.map((response) => response.data.base64);

    // Update state with fetched images
    setImagesReal(base64Images);
    // return base64Images;
  } catch (error) {
    console.error("Error fetching images:", error);
  }
};

export default function Pdf({
  dataObj,
  images: ig = [1, 2, 3],
  map,
  tables,
  prompts: prm,
  blockProps,
  propLocation,
  isGeneratingPdf = false,
}) {
  const [imagesReal, setImagesReal] = useState();
  const [images, setImages] = useState(ig);
  const [prompts, setPrompts] = useState(prm);
  useEffect(() => {
    console.log(prompts, "prompts");
    fetchImages(images, setImagesReal);
  }, [images]);
  const { popup } = usePopup();
  const { photo } = useMapContext();
  function runRegenerator(index) {
    popup.run(
      <>
        <div className="">
          <RegenerateImages
            index={index}
            images={images}
            prompts={prompts}
            setImages={setImages}
            setPrompts={setPrompts}
          />
        </div>
      </>
    );
  }
  return (
    <article id="pdfContainer22" className="Pdf">
      <div className="Pdfheader" style={{ background: "transparent" }}>
        <div className={isGeneratingPdf ? "element-to-print" : ""}>
          <img
            style={{ width: "80px" }}
            src="https://res.cloudinary.com/dfbtwwa8p/image/upload/v1721790549/kotxkxycpfeeocxnirg2.png"
            alt="Logo"
            className="logo"
          />
          <section className="intro">
            <div className="intro">
              <p>
                {
                  dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"][
                    "نوع_المشروع"
                  ]
                }{" "}
                في {dataObj["propLocation"]}
              </p>
              <p>مقدمة</p>
            </div>
            <div className="qoute">
              {dataObj["مقدمة"]}
              <div>،،</div>
            </div>
          </section>
        </div>
        <div className={isGeneratingPdf ? "element-to-print" : ""}>
          <section className="outerside_design2">
            <div>
              <p>التصميم الخارجي</p>
              <div>
                <img
                  onDoubleClick={() => {
                    runRegenerator(1);
                  }}
                  src={(imagesReal && imagesReal[1]) || images[1]}
                />
              </div>
            </div>
            <section></section>
          </section>
          <section className="outerside_design2">
            <div>
              <p>مقترحات</p>
              <div>
                <img
                  onDoubleClick={() => {
                    runRegenerator(0);
                  }}
                  src={(imagesReal && imagesReal[0]) || images[0]}
                />
              </div>
            </div>
            <section></section>
          </section>
        </div>
      </div>
      <div className={isGeneratingPdf ? "element-to-print" : ""}>
        <section className="outerside_design2">
          <div>
            <p>التصميم الداخلي</p>
            <div>
              <img
                onDoubleClick={() => {
                  runRegenerator(2);
                }}
                src={(imagesReal && imagesReal[2]) || images[2]}
              />
            </div>
          </div>
          <section></section>
        </section>
        <section className="outerside_design2">
          <div>
            <p>الموقع</p>
            <div className="w-full h-full ">
              <img style={{ width: "80%" }} src={map || photo} />
            </div>
          </div>
          <section></section>
        </section>
      </div>

      <section className="">{tables}</section>
      <div className={isGeneratingPdf ? "element-to-print" : ""}>
        <section>
          <p className="intro">ملخص تنفيذي</p>
          <div className="qoute">
            {dataObj["ملخص_تنفيذي"]}
            <div>،،</div>
          </div>
        </section>
      </div>
    </article>
  );
}

function RegenerateImages({
  images = [1, 1],
  prompts = [1, 1],
  index = 0,
  setImages,
  setPrompts, // Assuming setPrompts is passed as a pro
}) {
  const [img, setImg] = useState(null);
  // const [loading,setLoading] = useState()
  const [loading, setLoading] = useState(false);
  const handleGenerate = async () => {
    try {
      setLoading(true);
      const generatedData = await generateImg(prompts[index]);
      setImg(generatedData.url);
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = generatedData.url;
        return updatedImages;
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error generating image:", error);
    }
  };

  const handlePromptChange = (e) => {
    const updatedPrompt = e.target.value;
    setPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      updatedPrompts[index] = updatedPrompt;
      return updatedPrompts;
    });
  };

  if (index < 0 || index >= prompts.length) {
    return <div>Error: Invalid prompt index</div>;
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="bg-neutral-50 flex justify-center items-center border-neutral-300 py-4 dark:border-neutral-700 dark:bg-neutral-900 rounded-md border">
        <img
          style={{ width: "60%" }}
          src={img || images[index]}
          alt="Generated"
        />
      </div>

      <div className="relative w-full">
        <label htmlFor="aiPrompt" className="sr-only">
          AI prompt
        </label>
        <textarea
          id="aiPrompt"
          className="w-full h-[100px] border-outline bg-neutral-50 border border-neutral-300 rounded-md px-2 py-2 pl-10 pr-24 text-sm text-neutral-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black disabled:cursor-not-allowed disabled:opacity-75 dark:border-neutral-700 dark:bg-neutral-900/50 dark:text-neutral-300 dark:focus-visible:outline-white"
          defaultValue={prompts[index]}
          onChange={handlePromptChange}
          name="prompt"
          placeholder="Ask AI ..."
        />
        <button
          disabled={loading}
          type="button"
          className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer bg-black rounded-md px-2 py-1 text-xs tracking-wide text-neutral-100 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black active:opacity-100 active:outline-offset-0 dark:bg-white dark:text-black dark:focus-visible:outline-white"
          onClick={handleGenerate}
        >
          {loading ? "Generating" : "Generate"}
        </button>
      </div>
    </div>
  );
}
