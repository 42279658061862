import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { LangProvider } from "./Hooks/LangContext.jsx";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Hooks/authContext.jsx";
import App from "./App.jsx";
import "./index.css";
import { MapProvider } from "./Hooks/mapContext.jsx";
import PopupProvider from "./Hooks/popup.jsx";
import { SnackbarProvider } from "./Hooks/SnackBar.jsx";
import RefetchProvider from "./Hooks/RefetchContext.jsx";

createRoot(document.getElementById("root")).render(
  // <StrictMode>
  <Router>
    <PopupProvider>
      <AuthProvider>
        <MapProvider>
          <LangProvider>
            <SnackbarProvider>
              <RefetchProvider>
                <App />
              </RefetchProvider>
            </SnackbarProvider>
          </LangProvider>
        </MapProvider>
      </AuthProvider>
    </PopupProvider>
  </Router>
  // </StrictMode>
);
