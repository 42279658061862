import { GrLanguage } from "react-icons/gr";
import { useLang } from "../../Hooks/LangContext";
import { CircleUserRound } from "lucide-react";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "../../Hooks/authContext";
import { Link, useNavigate } from "react-router-dom";
import StudyCasesApi from "../../apis/StudyCases";
import { useRefetch } from "../../Hooks/RefetchContext";

export default function Header() {
  const { lang, setLang } = useLang();
  const { auth, account } = useAuth();
  const { refetch } = useRefetch();
  const [userButtonActive, setUserButtonActive] = useState(false);
  const [userPlan, setUserPlan] = useState({
    type: account.subscription,
    totalCases: account.studyCases,
    remainingCases: account.remainingCases,
  });
  const modalRef = useRef(null);

  function changeLang() {
    if (lang === "ar") {
      setLang("en");
    } else {
      setLang("ar");
    }
  }

  const openMenu = () => {
    setUserButtonActive(!userButtonActive);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setUserButtonActive(false);
    }
  };

  const getRemainingCases = () => {
    StudyCasesApi.getRemainingCases(auth, account._id)
      .then((res) => {
        console.log(res.data);
        setUserPlan({
          type: res.data.subscription,
          totalCases: res.data.total,
          remainingCases: res.data.remaining,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRemainingCases();
  }, [refetch]);

  useEffect(() => {
    if (userButtonActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userButtonActive]);
  // on scroll down disappear
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowHeader(false);
    } else {
      // Scrolling up
      setShowHeader(true);
    }

    setLastScrollY(currentScrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);
  // on scroll down disappear

  return (
    <motion.header
      initial={{ y: 0 }}
      animate={{ y: showHeader ? 0 : -100 }} // Adjust -80 to the height of your header
      transition={{ type: "tween", duration: 0.7 }}
      className="bg-white flex justify-between w-full  items-center px-10 md:px-20 h-20 border-b-2 border-[#659DBB] relative z-[99]"
    >
      <div className="flex justify-between md:justify-start w-full items-center   md:gap-20">
        <button
          onClick={() => {
            changeLang();
          }}
          className="flex items-center  justify-center 2xl:justify-between text-white bg-gradient-to-tr  h-[40px] from-[#4bbef7] to-[#9c9dfe] px-6 w- rounded-3xl gap-5"
        >
          <span className="h-fit">{lang === "ar" ? "en" : "ع"}</span>
          <GrLanguage />
        </button>
        <Link to={"/"} className="md:hidden block cursor-pointer">
          <img src="/icon.png" className="object-cover w-12 h-12" alt="" />
        </Link>
        {/* <div
          className={`md:hidden w-[223px] flex flex-col gap-2.5 ${
            lang === "ar" ? "items-end" : "items-start"
          } `}
        >
          <span className="font-bold">
            {lang === "ar"
              ? " متبقي " +
                userPlan.remainingCases +
                " دراسة " +
                (userPlan.type === "free" ? " مجانية" : "")
              : "remaining " +
                (userPlan.type === "free" ? "free" : "") +
                " Cases " +
                userPlan.remainingCases}
          </span>
          <span className="w-full h-2 bg-[#EAEAEA] rounded-full relative">
            <span
              style={{
                width: `${
                  (userPlan.remainingCases / userPlan.totalCases) * 100
                }%`,
              }}
              className={`h-2 ${
                userPlan.remainingCases <= 3 ? "bg-red-500" : "bg-[#9A9DFE]"
              } rounded-full absolute top-0 ${
                lang === "ar" ? "right-0" : "left-0"
              }`}
            ></span>
          </span>
          {(userPlan.type !== "Golden" || userPlan.remainingCases < 3) && (
            <Link
              to={"/ourplans"}
              className="w-full  px-[10px] py-1 font-bold text-[#4DBDF7] text-center rounded border-2 border-[#4DBDF7]"
            >
              {lang === "ar"
                ? "احصل على التجربة الكاملة"
                : "Get the full experience"}
            </Link>
          )}
        </div> */}
        <button onClick={openMenu} className="relative">
          {userButtonActive ? (
            <svg
              width="40"
              height="40"
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29 29C33.3078 29 36.8 25.5078 36.8 21.2C36.8 16.8922 33.3078 13.4 29 13.4C24.6922 13.4 21.2 16.8922 21.2 21.2C21.2 25.5078 24.6922 29 29 29Z"
                fill="#DBDBDB"
              />
              <path
                d="M29 55C43.3594 55 55 43.3594 55 29C55 14.6406 43.3594 3 29 3C14.6406 3 3 14.6406 3 29C3 43.3594 14.6406 55 29 55Z"
                fill="#DBDBDB"
              />
              <path
                d="M44.5197 49.8C44.106 42.2821 41.8042 36.8 28.9997 36.8C16.1955 36.8 13.8938 42.2821 13.48 49.8"
                fill="#DBDBDB"
              />
              <path
                d="M44.5197 49.8C44.106 42.2821 41.8042 36.8 28.9997 36.8C16.1955 36.8 13.8938 42.2821 13.48 49.8M36.8 21.2C36.8 25.5078 33.3078 29 29 29C24.6922 29 21.2 25.5078 21.2 21.2C21.2 16.8922 24.6922 13.4 29 13.4C33.3078 13.4 36.8 16.8922 36.8 21.2ZM55 29C55 43.3594 43.3594 55 29 55C14.6406 55 3 43.3594 3 29C3 14.6406 14.6406 3 29 3C43.3594 3 55 14.6406 55 29Z"
                stroke="#333333"
                stroke-width="4.93"
                stroke-linecap="round"
              />
            </svg>
          ) : (
            <svg
              width="40"
              height="40"
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.5197 49.8C44.106 42.2821 41.8042 36.8 28.9997 36.8C16.1955 36.8 13.8938 42.2821 13.48 49.8M36.8 21.2C36.8 25.5078 33.3078 29 29 29C24.6922 29 21.2 25.5078 21.2 21.2C21.2 16.8922 24.6922 13.4 29 13.4C33.3078 13.4 36.8 16.8922 36.8 21.2ZM55 29C55 43.3594 43.3594 55 29 55C14.6406 55 3 43.3594 3 29C3 14.6406 14.6406 3 29 3C43.3594 3 55 14.6406 55 29Z"
                stroke="#333333"
                stroke-width="4.93"
                stroke-linecap="round"
              />
            </svg>
          )}
          <AnimatePresence>
            {userButtonActive && (
              <UserMenuModal
                modalRef={modalRef}
                planType={userPlan.type}
                isOpen={userButtonActive}
                userPlan={userPlan}
              />
            )}
          </AnimatePresence>
        </button>

        <div
          className={`md:flex  w-[223px] hidden flex-col gap-1 ${
            lang === "ar" ? "items-end" : "items-start"
          } `}
        >
          <span className="font-bold">
            {lang === "ar"
              ? " متبقي " +
                userPlan.remainingCases +
                " دراسة " +
                (userPlan.type === "free" ? " مجانية" : "")
              : "remaining " +
                (userPlan.type === "free" ? "free" : "") +
                " Cases " +
                userPlan.remainingCases}
          </span>
          <span className="w-full h-2 bg-[#EAEAEA] rounded-full relative">
            <span
              style={{
                width: `${
                  (userPlan.remainingCases / userPlan.totalCases) * 100
                }%`,
              }}
              className={`h-2 ${
                userPlan.remainingCases <= 3 ? "bg-red-500" : "bg-[#9A9DFE]"
              } rounded-full absolute top-0 ${
                lang === "ar" ? "right-0" : "left-0"
              }`}
            ></span>
          </span>
          {(userPlan.type !== "Golden" || userPlan.remainingCases < 3) && (
            <Link
              to={"/ourplans"}
              className="w-full  px-[10px]  font-bold text-[#4DBDF7] text-center rounded border-2 border-[#4DBDF7]"
            >
              {lang === "ar"
                ? "احصل على التجربة الكاملة"
                : "Get the full experience"}
            </Link>
          )}
        </div>
      </div>
      <Link to={"/"} className="lg:block hidden cursor-pointer">
        <img
          src="/landingpage/Real-Investmentheader.png"
          className="object-cover w-[180px]"
          alt=""
        />
      </Link>
      <Link to={"/"} className="md:block hidden cursor-pointer">
        <img src="/icon.png" className="object-cover w-12 h-12" alt="" />
      </Link>
    </motion.header>
  );
}

const UserMenuModal = ({ modalRef, planType, isOpen, userPlan }) => {
  const { lang } = useLang();
  const { account, logout } = useAuth();
  const nav = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  const initial = {
    height: 0,
    width: 0,
  };
  const signOut = () => {
    logout();
    nav("/");
  };
  const animate = {
    height: 420,
    width: 362,
    transition: {
      duration: 0.3,
    },
  };
  const exit = {
    height: 0,
    width: 0,
    transition: {
      duration: 0.3,
    },
  };

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);
  console.log(userPlan);
  return (
    <motion.div
      ref={modalRef}
      initial={initial}
      animate={animate}
      exit={exit}
      className={`w-[342px] h-[358px] p-4 md:left-0 -right-8 bg-white border-2 border-[#D7D7D7] rounded-[10px] absolute top-11 z-50 flex flex-col gap-2 justify-start ${
        lang === "ar" ? "items-right" : "items-left"
      }`}
      onAnimationStart={() => setIsAnimating(true)}
      onAnimationComplete={() => setIsAnimating(false)}
    >
      {!isAnimating && isOpen && (
        <>
          <div
            className={`rounded-[10px] w-full  flex mb-2 ${
              lang === "ar" ? " flex-row-reverse" : " justify-start"
            } items-center  border-2 border-[#4DBDF7]`}
          >
            <div
              className={`px-1 w-1/2 py-3  flex mb-2 ${
                lang === "ar" ? " flex-row-reverse" : "justify-start"
              } items-center gap-2`}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 58 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.5197 49.8C44.106 42.2821 41.8042 36.8 28.9997 36.8C16.1955 36.8 13.8938 42.2821 13.48 49.8M36.8 21.2C36.8 25.5078 33.3078 29 29 29C24.6922 29 21.2 25.5078 21.2 21.2C21.2 16.8922 24.6922 13.4 29 13.4C33.3078 13.4 36.8 16.8922 36.8 21.2ZM55 29C55 43.3594 43.3594 55 29 55C14.6406 55 3 43.3594 3 29C3 14.6406 14.6406 3 29 3C43.3594 3 55 14.6406 55 29Z"
                  stroke="#333333"
                  stroke-width="4.93"
                  stroke-linecap="round"
                />
              </svg>

              <div
                className={`flex flex-col ${
                  lang === "ar" ? "items-end" : "items-start"
                } justify-center`}
              >
                <span className="text-xl font-bold">{account.fullName}</span>
                <span className="px-2 py-1 rounded-full font-bold text-xs border-2 border-gray-400">
                  {planType} {lang === "ar" ? "خطه" : "plan"}
                </span>
              </div>
            </div>

            <div
              className={`md:hidden  w-[48%] flex flex-col gap-2.5 ${
                lang === "ar" ? "items-end" : "items-start"
              } `}
            >
              <span className="font-bold">
                {lang === "ar"
                  ? " متبقي " +
                    userPlan.remainingCases +
                    " دراسة " +
                    (userPlan.type === "free" ? " مجانية" : "")
                  : "remaining " +
                    (userPlan.type === "free" ? "free" : "") +
                    " Cases " +
                    userPlan.remainingCases}
              </span>
              <span className="w-full h-2 bg-[#EAEAEA] rounded-full relative">
                <span
                  style={{
                    width: `${
                      (userPlan.remainingCases / userPlan.totalCases) * 100
                    }%`,
                  }}
                  className={`h-2 ${
                    userPlan.remainingCases <= 3 ? "bg-red-500" : "bg-[#9A9DFE]"
                  } rounded-full absolute top-0 ${
                    lang === "ar" ? "right-0" : "left-0"
                  }`}
                ></span>
              </span>
            </div>
          </div>
          <Link
            to={"/"}
            className={`w-full p-3 rounded-[10px] bg-[#F6F6F6] hover:text-[#4DBDF7] hover:bg-[#f6f6f6] duration-150  ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {lang === "ar" ? "الرئيسية" : "Home"}
          </Link>
          <Link
            to={"/settings"}
            className={`w-full p-3 rounded-[10px] bg-[#F6F6F6] hover:text-[#4DBDF7] hover:bg-[#f6f6f6] duration-150  ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {lang === "ar" ? "حسابي" : "My account"}
          </Link>
          <Link
            to={"/ourplans"}
            className={`w-full p-3 rounded-[10px] bg-[#F6F6F6] hover:text-[#4DBDF7] hover:bg-[#f9f9f9] duration-150  ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {lang === "ar" ? "الإشتراكات" : "Subscriptions"}
          </Link>
          <Link
            to={"/FeasibilityStudies"}
            className={`w-full p-3 rounded-[10px] bg-[#F6F6F6] hover:text-[#4DBDF7] hover:bg-[#f6f6f6] duration-150  ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {lang === "ar" ? "دراسات الجدوى الخاصة بي" : "My Study Cases"}
          </Link>
          <button
            onClick={signOut}
            className={`w-full p-3 rounded-[10px] bg-[#F6F6F6] hover:text-[#4DBDF7] hover:bg-[#f6f6f6] duration-150  ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {lang === "ar" ? "تسجيل الخروج" : "Sign out"}
          </button>
        </>
      )}
    </motion.div>
  );
};
