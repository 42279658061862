import SingnIn from "./pages/signin/SignIn";
import SignUp from "./pages/signup/SignUp";
import RealStateInvestmentAi from "./pages/RealStateInvestmentAi/RealStateInvestmentAi";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import html2pdf from "html2pdf.js";
import PdfApp from "./pages/components/pdf";
import DownloadPage from "./pages/converter";
import useKonvaWrapper from "./konva";
import { useAuth } from "./Hooks/authContext";
import { ErrorPage } from "./pages/error";
import FeasibilityStudies from "./pages/FeasibilityStudies/FeasibilityStudies";
import OurPlans from "./pages/ourPlans/OurPlans";
import SettingsPage from "./pages/settings/Settings";
import LandingPage from "./pages/landingpage/LandingPage";
import RealStateInvestmentAiNew from "./pages/RealStateInvestmentAi/RealStateInvestmentAiNew";
export default function App() {
  const { auth } = useAuth();
  return (
    <>
      <Routes>
        {!auth && (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/singnin" element={<SingnIn />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        )}
        {auth && (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/investmentAinew" element={<RealStateInvestmentAiNew />} />
            <Route path="/investmentAi" element={<RealStateInvestmentAi />} />
            <Route path="/convert" element={<DownloadPage />} />
            <Route path="/test" element={<PdfApp />}></Route>
            {/* <Route path="/land" element={<LandOrProperty />}></Route> */}
            <Route path="/ourplans" element={<OurPlans />} />
            <Route
              path="/FeasibilityStudies"
              element={<FeasibilityStudies />}
            ></Route>
            <Route path="/settings" element={<SettingsPage />}></Route>
          </>
        )}
        <Route path="/*" element={<ErrorPage />}></Route>
      </Routes>
    </>
  );
}
