import Header from "../components/Header";
import { useLang } from "../../Hooks/LangContext";
import GridentBorders from "../components/BorderGrident";
import { UnKnown } from "../../svgIcons/SettingsIcons";
import BasicInfo from "./basicInfo/BasicInfo";
import { LangText } from "../../language/LangElements";
import PasswordEdit from "./PasswordEdit/PasswordEdit";
import { useState } from "react";
import { useAuth } from "../../Hooks/authContext";
export default function Settings() {
  const { lang } = useLang();
  const { account } = useAuth();

  return (
    <div className="min-h-screen ">
      <Header />
      <div className="flex flex-col gap-10 mt-20 mb-5 w-[75%] mx-auto  ">
        <GridentBorders
          gridentStyle={"90deg, #4DBDF7 0%, #9A9DFE 100%"}
          resetColor={"bg-white p-4 md:!justify-end "}
          rounded={"21px"}
          strockWidth={"1px"}
        >
          <div className="flex md:flex-row flex-col-reverse gap-4 items-center">
            <div className={` flex flex-col `}>
              <h2 className="font-bold text-right text-[30.65px]">
                {account.fullName}
              </h2>
              <button className="text-right ml-auto w-fit border px-2 py-1 rounded-[16.48px] border-[#A5A5A5]">
                {lang === "ar" ? "الخطة المجانية" : "Free Plan"}
              </button>
            </div>
            {UnKnown}
          </div>
        </GridentBorders>
        <BasicInfo />
        <PasswordEdit />
      </div>
    </div>
  );
}
