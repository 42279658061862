import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaHome, FaSearch } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FaUserGroup } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import PropTypes from "prop-types";
import { IoMdAdd } from "react-icons/io";
import { useLang } from "../../../Hooks/LangContext";
import { useAuth } from "../../../Hooks/authContext";
import { LangText } from "../../../language/LangElements";

// import { img } from "framer-motion/client";

export default function LandingPageHeader() {
  const { lang, setLang } = useLang();
  const { auth, account } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  function changeLang() {
    if (lang === "ar") {
      setLang("en");
    } else {
      setLang("ar");
    }
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const activeElement = document.querySelector(
      `a[href='${location.pathname}']`
    );
    setActiveLink(activeElement);
  }, [location.pathname]);

  return (
    <header
      ref={navRef}
      className="border-b fixed top-0 z-50  flex justify-between bg-white items-center border-[#9C9C9C] w-full text-[#363330] font-bold text-[20.4px] h-[80px] py-4 px-10 xl:pl-[153px] xl:pr-[93px] xl:py-8 lg:pl-[80px] lg:pr-[60px] lg:py-8"
    >
      <aside className="flex items-center xl:gap-14 lg:gap-8 gap-4">
        <button onClick={changeLang}>{lang === "ar" ? "En" : "Ar"}</button>
        {auth ? (
          <button
            onClick={toggleMenu}
            className="w-[45px] relative h-[45px] rounded-full bg-gradient-to-t from-main-blue to-main-purple flex justify-center items-center group"
          >
            <div
              className={`bg-white overflow-clip relative w-[41px] h-[41px] rounded-full text-gray-border group-hover:bg-gradient-to-t from-main-blue to-main-purple group-hover:text-white duration-300 ${
                openMenu &&
                "bg-gradient-to-t from-main-blue to-main-purple text-[#fff]"
              }`}
            >
              {account?.photoLink ? (
                <img
                  src={account.photoLink}
                  alt="profile"
                  className="w-full h-full object-fill rounded-full"
                />
              ) : (
                <IoPersonSharp size={41} className="absolute -bottom-1" />
              )}
            </div>
            <AnimatePresence>
              {openMenu && <AccountMenu close={() => setOpenMenu(false)} />}
            </AnimatePresence>
          </button>
        ) : (
          <Link
            to={"/singnin"}
            title={lang === "ar" ? "تسجيل الدخول" : "Login"}
            className="bg-main-purple bg-[#9A9DFE] py-[7.32px] px-[8.13px] rounded-[20px] text-white"
          >
            {lang === "ar" ? "تسجيل الدخول " : "Login"}
          </Link>
        )}
      </aside>
      <aside className="hidden md:flex items-center gap-5 tracking-wider relative">
        {(location.pathname === "/owners" ||
          location.pathname.startsWith("/owners") ||
          location.pathname === "/lands" ||
          location.pathname.startsWith("/lands") ||
          location.pathname === "/") && (
          <div
            className="absolute bottom-0 left-0 h-0.5 bg-main-purple transition-all duration-300"
            style={{
              width: hoveredLink
                ? hoveredLink.offsetWidth
                : activeLink
                ? activeLink.offsetWidth
                : "0",
              transform: `translateX(${
                hoveredLink
                  ? hoveredLink.offsetLeft
                  : activeLink
                  ? activeLink.offsetLeft
                  : "0"
              }px)`,
            }}
          ></div>
        )}
        <div className="group">
          <a
            href={"#Pricing"}
            className={`hover:text-[#a8aaff] duration-300 relative ${
              location.pathname === "/owners" ? "text-main-purple" : ""
            }`}
            onMouseEnter={(e) => setHoveredLink(e.target)}
            onMouseLeave={() => setHoveredLink(null)}
          >
            {lang === "ar" ? "التسعير" : "Pricing"}
          </a>
        </div>
        <div className="group">
          <a
            href="#examples"
            className={`hover:text-[#a8aaff] duration-300 relative ${
              location.pathname === "/lands" ? "text-main-purple" : ""
            }`}
            onMouseEnter={(e) => setHoveredLink(e.target)}
            onMouseLeave={() => setHoveredLink(null)}
          >
            {lang === "ar" ? "أمثلة" : "Examples"}
          </a>
        </div>
        <div className="group">
          <Link
            to={"/"}
            className={`hover:text-[#a8aaff] duration-300 relative ${
              location.pathname === "/" ? "text-main-purple" : ""
            }`}
            onMouseEnter={(e) => setHoveredLink(e.target)}
            onMouseLeave={() => setHoveredLink(null)}
          >
            {lang === "ar" ? "الرئيسية" : "Main"}
          </Link>
        </div>
      </aside>
      <Link className="md:hidden block w-12" to={"/"}>
        <img src="/icon.png" className="object-cover" alt="" />
      </Link>
      <Link
        className="md:block hidden"
        to={"/"}
        onClick={() => window.scrollTo(0, 0)}
      >
        <img
          src="/landingpage/Real-Investmentheader.png"
          className="object-cover"
          alt=""
        />
      </Link>
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="md:hidden block"
      >
        <GiHamburgerMenu />
      </button>
      <SideBar isSidebarOpen={isSidebarOpen} />
    </header>
  );
}

const AccountMenu = ({ close }) => {
  const { lang } = useLang();
  const { account, logout } = useAuth();
  const MenuRef = useRef(null);
  const nav = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (MenuRef.current && !MenuRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -60 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, y: -60, transition: { duration: 0.3 } }}
      ref={MenuRef}
      className="absolute top-12 border md:left-0 left-1/2 -translate-x-1/2 border-gray-border rounded-[20px] p-4 bg-white w-[216px] h-[220px]"
    >
      <ul
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={`text-bold text-lg ${
          lang === "ar" ? "text-right" : "text-left"
        }`}
      >
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={"/investmentAinew"}>
            {lang === "ar" ? "لوحة التحكم" : "Dashboard"}
          </Link>
        </li>
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={`/settings`}>
            {lang === "ar" ? "الملف الشخصي" : "Profile"}
          </Link>
        </li>
        <li className="border-b border-gray-border mb-4 pb-2 hover:px-2 duration-300 hover:text-gray-600">
          <Link to={"/FeasibilityStudies"}>
            {lang === "ar" ? "مشاريعي" : "My Projects"}
          </Link>
        </li>
        <li className="text-red-600 hover:px-2 duration-300 hover:text-red-300">
          <button
            onClick={() => {
              close();
              logout();
              nav("/");
            }}
          >
            {lang === "ar" ? "تسجيل الخروج" : "Logout"}
          </button>
        </li>
      </ul>
    </motion.div>
  );
};

export function SideBar({ isSidebarOpen }) {
  const [explore, setExplore] = useState(false);
  const [community, setCommunity] = useState(false);
  const { auth } = useAuth();
  const { lang } = useLang("ar");
  const { pathname } = useLocation();
  return (
    <motion.div
      initial={{ left: 0 }}
      animate={isSidebarOpen ? { x: 0 } : { x: -1000 }}
      transition={{ type: "spring", stiffness: 40 }}
      className={`fixed z-20 menu  h-full w-[300px] bg-transparent shadow-[1px_0px_5px_#00000026]  top-20 ${
        lang === "ar" ? "right-0" : "left-0"
      }`}
    >
      <div className="">
        <aside className=" ">
          <section>
            <div
              className={`flex ${
                lang === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <div
                className={`${
                  pathname === "/" ? "bg-[#9192ff]" : "bg-white"
                }  w-[10px]`}
              />
              <div
                className={`${
                  pathname === "/" ? "bg-[#9C9DFE]" : "bg-white"
                } grow   p-4`}
              >
                <div
                  className={`flex cursor-pointer gap-10 ${
                    lang === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <FaHome
                    className={`${
                      pathname === "/" ? "text-white" : "text-[#c9c9c9]"
                    } text-xl`}
                  />
                  <Link to="/">
                    <LangText
                      className={`${
                        pathname === "/" ? "text-white" : "text-black"
                      }`}
                      en="Main"
                      ar="الرئيسي"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section
          // onClick={() => {
          //   setExplore(!explore);
          // }}
          >
            <div className="flex flex-col">
              <div
                className={`flex ${
                  lang === "ar" ? "flex-row-reverse" : "flex-row"
                } `}
              >
                <div
                  className={`${
                    pathname === "/lands"
                      ? // pathname === "/agencies" ||
                        // pathname === "/agents"
                        "bg-[#9192ff]"
                      : "bg-white"
                  } w-[10px]`}
                />

                <div
                  className={`${
                    pathname === "/lands"
                      ? // pathname === "/agencies" ||
                        // pathname === "/agents"
                        "bg-[#9C9DFE]"
                      : "bg-white"
                  }  grow  p-4 `}
                >
                  <div
                    className={`flex cursor-pointer gap-10 ${
                      lang === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <FaSearch
                      className={`${
                        pathname === "/lands"
                          ? // pathname === "/agencies" ||
                            // pathname === "/agents"
                            "text-white"
                          : "text-[#c9c9c9]"
                      } text-xl`}
                    />
                    <p className="cursor-pointer">
                      <a href="#examples">
                        <div>
                          <LangText
                            className={`${
                              pathname === "/lands"
                                ? // pathname === "/agencies" ||
                                  // pathname === "/agents"
                                  "text-white"
                                : "text-black"
                            }`}
                            en="Examples"
                            ar="أمثلة"
                          />
                        </div>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <motion.div
								initial={{ height: 0, overflow: "hidden" }}
								animate={
									explore
										? {
												marginTop: "20px",
												marginBottom: "20px",
												height: "auto",
										  }
										: { height: 0 }
								}
								className={`overflow-y-hidden px-4 flex ${
									lang === "ar" ? "flex-row-reverse" : "flex-row"
								} items-center`}
							>
								<motion.div
									initial={{ height: 0, overflow: "hidden" }}
									animate={explore ? { height: "90px" } : { height: 0 }}
									transition={{ type: "spring", stiffness: 40, delay: 0.5 }}
									className="border border-[#9c9dfe]  m-5 mr-3"
								/>
								<div className="flex [&>div]:cursor-pointer flex-col gap-2">
									<div>
										<Link to="/proberties">
											<LangText en="Properties" ar="ملكيات" />
										</Link>
									</div>
									<div>
										<Link to="/agencies">
											<LangText en="Agencies" ar="الوكالات" />
										</Link>
									</div>
									<div>
										<Link to="/agents">
											<LangText en="Agents" ar="عملاء" />
										</Link>
									</div>
									<div>
										<Link to="/developers">
											<LangText en="Developers" ar="مطورين العقارات" />
										</Link>
									</div>
									<div>
										<Link to="/projects">
											<LangText en="projects" ar="مشاريع" />
										</Link>
									</div>
								</div>
							</motion.div> */}
            </div>
          </section>

          <section
          // onClick={() => {
          //   setCommunity(!community);
          // }}
          >
            <div className="flex flex-col">
              <div
                className={`flex ${
                  lang === "ar" ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <div
                  className={`${
                    pathname === "/Owners"
                      ? // || pathname === "/about"
                        "bg-[#9192ff]"
                      : "bg-white"
                  }  w-[10px]`}
                />
                <div
                  className={`${
                    pathname === "/Owners"
                      ? //  || pathname === "/about"
                        "bg-[#9C9DFE]"
                      : "bg-white"
                  } grow  p-4`}
                >
                  <div
                    className={`cursor-pointer flex gap-10 ${
                      lang === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                    href=""
                  >
                    <FaUserGroup
                      className={`${
                        pathname === "/Owners"
                          ? //  || pathname === "/about"
                            "text-white"
                          : "text-[#c9c9c9]"
                      } text-xl`}
                    />
                    <a herf={`#Pricing`}>
                      <LangText
                        className={`${
                          pathname === "/Owners"
                            ? // || pathname === "/about"
                              "text-white"
                            : "text-black"
                        }`}
                        en="Pricing"
                        ar="التسعير"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* <motion.div
                initial={{ height: 0, overflow: "hidden" }}
                animate={
                  community
                    ? {
                        marginTop: "20px",
                        marginBottom: "20px",
                        height: "auto",
                      }
                    : { height: 0 }
                }
                className={`overflow-y-hidden px-4 flex ${
                  lang === "ar" ? "flex-row-reverse" : "flex-row"
                } items-center`}
              >
                <motion.div
                  initial={{ height: 0, overflow: "hidden" }}
                  animate={community ? { height: "20px" } : { height: 0 }}
                  transition={{ type: "spring", stiffness: 40, delay: 0.5 }}
                  className=" border border-[#9c9dfe]   m-5 mr-3"
                />
                <div className="flex cursor-pointer flex-col gap-2">
                  <div>
                    <Link to="/about">
                      {" "}
                      <LangText en="About Us" ar="معلومات عنا" />
                    </Link>
                  </div>
                </div>
              </motion.div> */}
              {/* {auth && (
                <Link
                  to={"/create-project"}
                  dir={lang === "ar" ? "rtl" : "ltr"}
                  className="text-white text-xl font-bold bg-main-purple px-7 py-3 flex items-center gap-9"
                >
                  <IoMdAdd />
                  {lang === "ar" ? "مشروع جديد" : "New Project"}
                </Link>
              )} */}
            </div>
          </section>
        </aside>
      </div>
    </motion.div>
  );
}

SideBar.propTypes = {
  isSidebarOpen: PropTypes.bool,
};

AccountMenu.propTypes = {
  close: PropTypes.func,
};
