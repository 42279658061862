import {
  RobotShadow,
  whywetriangesleft,
  whywetriangesright,
  AiRobot,
  reports,
  timerSpeed,
  DashedLineAnimation,
  HeroSectionCirculLeft,
  HeroSectionCirculRight,
  Feasibilitystudy,
  Suggestions,
  invesrment,
} from "./icons/LandingPageIcons";
import { Card } from "./cards/Card";
import { useAuth } from "../../Hooks/authContext";
import { Link } from "react-router-dom";
// import React, { useRef } from "react";
import { animate, delay, motion, useAnimation, useInView } from "framer-motion";
// import { useInView } from "react-intersection-observer";
import { useSnackbar } from "../../Hooks/SnackBar";
import { useRefetch } from "../../Hooks/RefetchContext";
import { useLang } from "../../Hooks/LangContext";
import LandingPageHeader from "./components/Header";
import { CircleCheck, Scale } from "lucide-react";
import { cheak } from "../../svgIcons/OurPlansIcon";
import LandingPageFooter from "./components/Footer";
// import { motion, useInView } from "framer-motion";
import UserApi from "../../apis/User";
import { useEffect, useRef, useState } from "react";
// import React, { useEffect, useState } from "react";
export default function LandingPage() {
  return (
    <>
      <LandingPageHeader />
      <div className="min-h-screen overflow-hidden flex flex-col gap-44 py-40">
        <HeroSection />
        <TheBestDecisionSecton />
        <WhyWeSection />
        <FeasibilityExamplesSection />
        <OurPlansSection />
        <JoinUsLastSection />
      </div>
      <LandingPageFooter />
    </>
  );
}

function HeroSection() {
  const { lang } = useLang();
  return (
    <section className="container mx-auto">
      <div
        style={{
          background:
            "radial-gradient(48.05% 132.25% at 50% 50%, #22AAFF 42.69%, #6572FF 100%)",
        }}
        className="w-full relative  min-h-[1080px] py-10 rounded-[130px]"
      >
        <div className="absolute -top-24  left-0">{HeroSectionCirculLeft}</div>
        <div className="absolute top-0  right-0">
          <img src="/landingpage/herosectionrightCircule.svg" alt="" />
        </div>
        <div className="px-10 z-10 relative">
          <div className="flex items-center    justify-between">
            <div className="w-full md:w-1/2 relative z-40">
              <img
                src="/landingpage/trianglewithrobot.svg"
                className="object-cover"
                alt=""
              />
            </div>
            <div
              className={`${
                lang === "ar" ? "text-right" : "text-left"
              } font-bold  md:flex hidden  flex-col gap-8 text-white w-1/2`}
            >
              <div
                className={` leading-tight  text-nowrap ${
                  lang === "ar" ? "text-[65px]" : "text-[45px]"
                } `}
              >
                <h2>{lang === "ar" ? "تحليل ذكي" : "Smart analysis"}</h2>
                <h2 className="">
                  {lang === "ar"
                    ? "لمستقبل مشروعك"
                    : "For the future of your project"}
                </h2>
              </div>
              <div>
                <p>
                  {lang === "ar"
                    ? "استفد من قوة التحليل الذكي لإنشاء دراسة جدوى دقيقة وشاملة لمشروعك. باستخدام أحدث تقنيات الذكاء الاصطناعي، نقدم لك توصيات مُفصلة وخطط مدروسة تستند إلى البيانات، لتبدأ مشروعك بثقة كاملة"
                    : "Harness the power of intelligent analysis to create an accurate and comprehensive feasibility study for your project. Using the latest AI technologies, we provide you with detailed recommendations and thoughtful plans based on data, so you can start your project with complete confidence."}
                </p>
              </div>
              <Link
                to={"/signup"}
                className={`flex justify-center items-center  ${
                  lang === "ar" ? "ml-auto" : "mr-auto"
                } w-[295px] h-[50px] 
                hover:border-[#060054] border border-white hover:bg-white bg-[#060054] 
                hover:text-[#060054] duration-300 text-white font-bold text-[20px] 
                rounded-[18px] shadow-[0px_4.11px_14.06px_0px_#00000091]`}
              >
                {lang === "ar"
                  ? "انشئ دراستك في دقائق"
                  : "Create your study in minutes"}
              </Link>
            </div>
          </div>
          <div className="font-bold text-white   flex flex-col gap-10 text-center">
            <h4 className="text-[60px]">
              {lang === "ar" ? "الخدمات التي نقدمها" : "Services we provide"}
            </h4>
            <div className="flex w-full justify-around">
              <div className="rounded-[21.99px] bg-gradient-to-bl p-[1px]  from-white hover:scale-105 duration-300 to-[rgba(102,102,102,0)] from-[-4.25%] to-[72.44%]">
                <div className=" bg-[#67c4ff] rounded-[21.99px] flex-col gap-5 h-[21vw] md:h-[20vw] w-[20vw] flex justify-center  items-center ">
                  <div className="w-[10vw]">
                    <Suggestions width={"10vw"} />
                  </div>
                  <p className="text-[2vw]">
                    {lang === "ar" ? "إقتراحات" : "Suggestions"}
                  </p>
                </div>
              </div>

              <div className="rounded-[21.99px] bg-gradient-to-bl p-[1px]  from-white hover:scale-105 duration-300 to-[rgba(102,102,102,0)] from-[-4.25%] to-[72.44%]">
                <div className=" bg-[#67c4ff] rounded-[21.99px]  flex-col gap-5 h-[21vw] md:h-[20vw] w-[20vw] flex justify-center  items-center ">
                  <div>
                    <img
                      src="/landingpage/investment.png"
                      className="object-cover w-[10vw]"
                      alt=""
                    />
                  </div>
                  <p className="text-[2vw]">
                    {lang === "ar"
                      ? "استشارات استثمارية"
                      : "Investment Consulting"}
                  </p>
                </div>
              </div>

              <div className="rounded-[21.99px] bg-gradient-to-bl p-[1px]  from-white hover:scale-105 duration-300 to-[rgba(102,102,102,0)] from-[-4.25%] to-[72.44%]">
                <div className=" bg-[#67c4ff] rounded-[21.99px] flex-col gap-5 h-[21vw] md:h-[20vw] w-[20vw] flex justify-center  items-center ">
                  <div>
                    <Feasibilitystudy width={"8vw"} />
                  </div>
                  <p className="text-[2vw]">
                    {lang === "ar"
                      ? "دراسات جدوى اقتصادية"
                      : "Economic feasibility studies"}
                  </p>
                </div>
              </div>
            </div>
            <section>
              <div
                className={` font-bold  flex md:hidden  flex-col gap-8 text-white w-full text-center`}
              >
                <div
                  className={` leading-tight  text-nowrap ${
                    lang === "ar"
                      ? "text-[40px] md:text-[65px]"
                      : "text-[25px] md:text-[45px]"
                  } `}
                >
                  <h2>{lang === "ar" ? "تحليل ذكي" : "Smart analysis"}</h2>
                  <h2 className="">
                    {lang === "ar"
                      ? "لمستقبل مشروعك"
                      : "For the future of your project"}
                  </h2>
                </div>
                <div>
                  <p>
                    {lang === "ar"
                      ? "استفد من قوة التحليل الذكي لإنشاء دراسة جدوى دقيقة وشاملة لمشروعك. باستخدام أحدث تقنيات الذكاء الاصطناعي، نقدم لك توصيات مُفصلة وخطط مدروسة تستند إلى البيانات، لتبدأ مشروعك بثقة كاملة"
                      : "Harness the power of intelligent analysis to create an accurate and comprehensive feasibility study for your project. Using the latest AI technologies, we provide you with detailed recommendations and thoughtful plans based on data, so you can start your project with complete confidence."}
                  </p>
                </div>
                <Link
                  to={"/signup"}
                  className={`flex justify-center items-center mx-auto w-[295px] h-[50px] 
                hover:border-[#060054] border border-white hover:bg-white bg-[#060054] 
                hover:text-[#060054] duration-300 text-white font-bold text-[20px] 
                rounded-[18px] shadow-[0px_4.11px_14.06px_0px_#00000091]`}
                >
                  {lang === "ar"
                    ? "انشئ دراستك في دقائق"
                    : "Create your study in minutes"}
                </Link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

function TheBestDecisionSecton() {
  const { lang } = useLang();
  const { auth } = useAuth();
  const variants = {
    hidden: { opacity: 0, x: 100 }, // Right side animation
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  const variantsRightOne = {
    hidden: { opacity: 0, x: 100 }, // Right side animation
    visible: { opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } },
  };
  const variantsRightTwo = {
    hidden: { opacity: 0, x: 100 }, // Right side animation
    visible: { opacity: 1, x: 0, transition: { delay: 1, duration: 0.5 } },
  };
  const variantsRightThree = {
    hidden: { opacity: 0, x: 100 }, // Right side animation
    visible: { opacity: 1, x: 0, transition: { delay: 1.5, duration: 0.5 } },
  };

  const leftVariants = {
    hidden: { opacity: 0, x: -100 }, // Left side animation
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  return (
    <section className="container flex flex-col gap-20 mx-auto">
      <div className="flex flex-col lg:flex-row justify-between gap-20">
        <motion.aside
          variants={leftVariants}
          initial="hidden"
          animate="visible"
          className="relative h-[474.28px] w-[380px] mx-auto md:w-[420px]"
        >
          <div className="absolute z-10 top-1/2 left-2 -translate-y-1/2 w-[350px] md:w-[400px]">
            <img
              src="/landingpage/robotTheBestDecisionSecton.png"
              className="object-cover"
              alt=""
            />
          </div>
          <div className="absolute z-10 top-[80%] -translate-y-1/2">
            {RobotShadow}
          </div>
          <div className="md:block hidden absolute w-[420px]">
            <img
              src="/landingpage/bgrobotTheBestDecisionSecton.png"
              className="object-cover"
              alt=""
            />
          </div>
          <div className="md:block hidden bg-[#4DBDF7BA] w-[400.19px] h-[400.19px] -translate-x-[40%] -right-1/2 -translate-y-1/2 -bottom-1/2 absolute -z-10 rounded-full"></div>
          <div className="md:block hidden bg-[#4DBDF7BA] w-[100.19px] h-[100.19px] -z-10 absolute -top-10 rounded-full"></div>
        </motion.aside>

        <motion.aside
          variants={variants}
          initial="hidden"
          animate="visible"
          className="grow text-center"
        >
          <section>
            <p className="text-[40px] font-bold">
              {lang === "ar" ? "كيف نساعدك في اتخاذ" : "How we help you make"}
            </p>
            <p className="text-[60px] md:text-[80px] font-bold text-transparent bg-gradient-to-tl from-[#4DBDF7] to-[#9A9DFE] bg-clip-text">
              {lang === "ar" ? "القرار الأفضل؟" : "The best decision?"}
            </p>
          </section>
          <section className="flex flex-col [&>div]:font-bold [&>div]:text-[20px]  [&>div]:w-[98%] [&>div]:relative gap-10 [&>div]:p-5 [&>div]:rounded-[19.83px] [&>div]:border-dashed [&>div]:border-[#B7B7B7] [&>div]:border-[2.97px]">
            <motion.div
              variants={variantsRightOne}
              initial="hidden"
              animate="visible"
              className="group"
            >
              {lang === "ar"
                ? "أدخل معلومات مشروعك"
                : "Enter your project information"}
              <div
                className={`duration-300 w-[0px] ${
                  lang === "ar"
                    ? "group-hover:w-[200px]"
                    : "group-hover:w-[280px]"
                } mx-auto h-[2px] rounded-[2px] bg-black`}
              />
              <div className="absolute text-[20px] bg-[#D9D9D9] rounded-full w-[30px] top-1/2 right-0 translate-x-1/2 -translate-y-1/2 h-[30px] flex justify-center items-center font-bold">
                {lang === "ar" ? "١" : "1"}
              </div>
            </motion.div>
            <motion.div
              variants={variantsRightTwo}
              initial="hidden"
              animate="visible"
              className="group"
            >
              {lang === "ar"
                ? "دع الذكاء الاصطناعي يحلل البيانات"
                : "Let AI analyze the data"}
              <div
                className={`duration-300 w-[0px] ${
                  lang === "ar"
                    ? "group-hover:w-[280px]"
                    : "group-hover:w-[210px]"
                } mx-auto h-[2px] rounded-[2px] bg-black`}
              />
              <div className="absolute text-[20px] bg-[#D9D9D9] rounded-full w-[30px] top-1/2 right-0 translate-x-1/2 -translate-y-1/2 h-[30px] flex justify-center items-center font-bold">
                {lang === "ar" ? "٢" : "2"}
              </div>
            </motion.div>
            <motion.div
              variants={variantsRightThree}
              initial="hidden"
              animate="visible"
              className="group"
            >
              {lang === "ar"
                ? "أحصل على دراسة جدوى مفصلة"
                : "Get a detailed feasibility study"}
              <div
                className={`duration-300 w-[0px] ${
                  lang === "ar"
                    ? "group-hover:w-[260px]"
                    : "group-hover:w-[280px]"
                } mx-auto h-[2px] rounded-[2px] bg-black`}
              />
              <div className="absolute text-[20px] bg-[#D9D9D9] rounded-full w-[30px] top-1/2 right-0 translate-x-1/2 -translate-y-1/2 h-[30px] flex justify-center items-center font-bold">
                {lang === "ar" ? "٣" : "3"}
              </div>
            </motion.div>
          </section>
        </motion.aside>
      </div>
      <Link
        to={auth ? "/investmentAi" : "/singnin"}
        className="text-white font-bold border-2 duration-300 hover:from-white hover:to-white rounded-[26px] py-5 w-fit mx-auto px-7 hover:text-[#0088D1] hover:border-[#0088D1] text-[20px] bg-gradient-to-tl from-[#4FBDF8] to-[#00A6FF]"
      >
        {lang === "ar"
          ? "اجعل قرارك الذكي يبدأ الآن"
          : "Make your smart decision starting now"}
      </Link>
    </section>
  );
}

function WhyWeSection() {
  const { lang } = useLang();

  // Animation Variants for fading in
  const variantsOne = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
  };
  const variantsTriangleLeft = {
    initial: { rotate: 0 }, // Start rotation at 0 degrees
    animate: { rotate: 360, transition: { duration: 0.5 } }, // Rotate to 360 degrees
  };
  const variantsTriangleRight = {
    initial: { rotate: 0 },
    animate: { rotate: -360, transition: { duration: 0.5 } },
  };
  const variantsTwo = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 1, duration: 0.5 } },
  };
  const variantsTree = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 1.5, duration: 0.5 } },
  };

  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <section className="flex flex-col gap-20">
      <section>
        <section className="mx-auto flex md:gap-10 items-center w-fit">
          <motion.div
            variants={variantsTriangleLeft}
            initial="initial"
            animate={inView && "animate"}
            className="md:block hidden"
          >
            {whywetriangesleft}
          </motion.div>
          <div className="flex md:flex-row md:gap-0 gap-10 flex-col items-center">
            <div className="w-[300px] md:w-1/2">
              <img
                src="/landingpage/whywerobot.png"
                className="object-cover"
                alt=""
              />
            </div>
            <div
              className={`${
                lang === "ar"
                  ? "text-right flex-row-reverse leading-10"
                  : "text-left flex-row leading-[60px]"
              } flex font-bold items-center`}
            >
              <div>
                <p className="text-[50px]">{lang === "ar" ? "لماذا" : "Why"}</p>
                <p className="text-[80px]">{lang === "ar" ? "نحن" : "We"}</p>
              </div>
              <p className="text-[200px]">{lang === "ar" ? "؟" : "?"}</p>
            </div>
          </div>
          <motion.div
            variants={variantsTriangleRight}
            initial="initial"
            animate={inView && "animate"}
            className="md:block hidden"
          >
            {whywetriangesright}
          </motion.div>
        </section>
      </section>

      <section className="relative flex flex-col gap-16">
        <div className="h-[500px] md:block hidden">
          <DashedLineAnimation />
        </div>
        <motion.section
          ref={ref}
          variants={variantsOne}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="shadow-[0px_4px_15.4px_0px_#00000066] mx-auto hover:scale-105 duration-300 left-[2%] bg-white font-bold text-[25px] p-5 w-[400px] -top-[3rem] xl:top-[7.25rem] flex justify-center items-center flex-col gap-5 md:absolute border-2 border-[#9A9DFE] rounded-[26.14px]"
        >
          {reports}
          <p>
            {lang === "ar"
              ? "تقارير مخصصة بناءً على احتياجاتك"
              : "Custom reports based on your needs"}
          </p>
        </motion.section>

        <motion.section
          ref={ref}
          variants={variantsTwo}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="shadow-[0px_4px_15.4px_0px_#00000066] mx-auto hover:scale-105 duration-300 left-1/2 md:-translate-x-1/2 top-24 text-white font-bold text-[25px] p-5 w-[400px] flex justify-center items-center flex-col gap-5 md:absolute bg-[#9A9DFE] rounded-[26.14px]"
        >
          {AiRobot}
          <p>
            {lang === "ar"
              ? "تقنيات الذكاء الاصطناعي المتقدمة"
              : "Advanced AI Technologies"}
          </p>
        </motion.section>

        <motion.section
          ref={ref}
          variants={variantsTree}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="shadow-[0px_4px_15.4px_0px_#00000066] mx-auto hover:scale-105 duration-300 right-[2%] top-[17rem] xl:top-[7.25rem] bg-white font-bold text-[25px] p-5 w-[400px] flex justify-center items-center flex-col gap-5 md:absolute border-2 border-[#9A9DFE] rounded-[26.14px]"
        >
          {timerSpeed}
          <p>
            {lang === "ar"
              ? "سرعة ودقة التقارير"
              : "Speed ​​and accuracy of reports"}
          </p>
        </motion.section>
      </section>
    </section>
  );
}

function FeasibilityExamplesSection() {
  const { lang } = useLang();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { auth } = useAuth();
  // Check if the component is in the viewport
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        // Check if the component is fully in view
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setIsVisible(true);
          window.removeEventListener("scroll", handleScroll); // Remove listener after it becomes visible
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility on mount

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup listener on component unmount
    };
  }, []);

  // Animation variants
  const variants = {
    hidden: { opacity: 0, x: 100 }, // Start from the right
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }, // End position
    hiddenLeft: { opacity: 0, x: -100 }, // Start from the left
  };
  const data = [
    {
      images: ["/Image2.png"],
      data: {
        العنوان: "تطوير مشروع سكني فردي في حي الرحمانية بالرياض",
        الموقع: " حي الرحمانية , الرياض",
      },
    },
    {
      images: ["/Image3.png"],
      data: {
        العنوان: "تطوير مبنى سكني في الزهراء، الشارقة",
        الموقع: " حي الرحمانية , الرياض",
      },
    },
  ];
  return (
    <section
      id="examples"
      className="flex gap-40 lg:gap-10 lg:flex-row flex-col container mx-auto"
      ref={ref}
    >
      <motion.aside
        className="w-full lg:w-[55%]"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={variants}
      >
        <div className="bg-transparent md:bg-[#9A9DFE] relative gap-3 w-full h-full md:h-[300px] rounded-[40px] flex md:flex-row flex-col justify-center items-center">
          <div className="md:block hidden absolute rounded-full w-[350px] h-[350px] -top-40 -right-10 -z-10 bg-gradient-to-bl from-[#4DBDF7] to-[#B0E4FF]"></div>
          <div className="md:block hidden bg-gradient-to-bl from-[#4DBDF7] to-[#B0E4FF] absolute rounded-full w-[300px] h-[300px] -left-5 -bottom-40 -z-10"></div>
          <Card data={data[0]} />
          <Card data={data[1]} />
        </div>
      </motion.aside>

      <motion.aside
        className={`${
          lang === "ar" ? "text-right" : "text-left"
        } w-full lg:w-[45%] grow flex flex-col gap-10`}
        initial="hiddenLeft"
        animate={isVisible ? "visible" : "hiddenLeft"}
        variants={variants}
      >
        <h2 className="font-bold text-[35px] md:text-[60px]">
          {lang === "ar"
            ? "أمثلة لدراسات جدوى"
            : "Examples of feasibility studies"}
        </h2>
        <p className="font-bold text-[23px]">
          {lang === "ar"
            ? "اكتشف كيف ساعدنا مشاريع مختلفة على النجاح من خلال دراسات جدوى متكاملة"
            : "Discover how we have helped different projects succeed through comprehensive feasibility studies."}
        </p>
        <Link
          to={auth ? "/FeasibilityStudies" : "/singnin"}
          className="text-white font-bold border-2 duration-300 hover:from-white hover:to-white rounded-[26px] py-5 w-fit mx-auto px-7 hover:text-[#0088D1] hover:border-[#0088D1] text-[20px] bg-gradient-to-tl from-[#4FBDF8] to-[#00A6FF]"
        >
          {lang === "ar" ? "اطلب دراستك الآن" : "Order your study now"}
        </Link>
      </motion.aside>
    </section>
  );
}

function OurPlansSection() {
  const { lang } = useLang();
  const { auth } = useAuth();
  const { openSnackbar } = useSnackbar();
  const { setRefetch } = useRefetch();

  const Premium = {
    Feature1:
      lang === "ar"
        ? "عدد دراسات جدوى لا نهائي"
        : "Endless feasibility studies",
    Feature2:
      lang === "ar"
        ? "عدد دراسات جدوى لا نهائي"
        : "Endless feasibility studies",
    Feature3:
      lang === "ar"
        ? "عدد دراسات جدوى لا نهائي"
        : "Endless feasibility studies",
  };

  const Free = {
    Feature1:
      lang === "ar"
        ? "عدد دراسات جدوى محدود"
        : "feasibility studies is Limited",
    Feature2:
      lang === "ar"
        ? "عدد دراسات جدوى محدود"
        : "feasibility studies is Limited",
    Feature3:
      lang === "ar"
        ? "عدد دراسات جدوى محدود"
        : "feasibility studies is Limited",
  };

  const subscribe = () => {
    UserApi.subscribe({ subscription: "Bronze" }, auth)
      .then((res) => {
        console.log(res);
        openSnackbar(
          lang === "ar" ? "تم الإشتراك بنجاح" : "Subscribed successfully"
        );
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(
          lang === "ar"
            ? "حدث خطأ أثناء الإشتراك"
            : "An error occurred while subscribing"
        );
      });
  };

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <section
      id="Pricing"
      className="flex justify-center gap-10 flex-col items-center"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} // Animation triggers only once
        variants={variants}
      >
        <h3
          className={`${
            lang === "ar" ? "flex-row-reverse" : "flex-row"
          } flex font-bold text-nowrap text-[30px] justify-center md:text-[60px]`}
        >
          <span>{lang === "ar" ? "خطط مصممة" : "Designed plans"}</span>
          <span className="bg-gradient-to-tr bg-clip-text text-transparent from-[#999EFE] from-[0.03%] via-[#00F7FF] via-[28.32%] to-[#52BCF8] to-[56.6%]">
            {lang === "ar" ? "لتحقيق أهدافك" : "To achieve your goals"}
          </span>
        </h3>
        <p className="text-center text-[#616161] font-bold text-[12px] md:text-[18px]">
          {lang === "ar"
            ? ".لدينا الخطة المثالية لمساعدتك علي تحقيق أهدافك بخطوات ثابتة وثقة كاملة "
            : "We have the perfect plan to help you achieve your goals with steady steps and complete confidence."}
        </p>
      </motion.div>
      <div className="flex md:flex-row flex-col gap-5 lg:gap-20 mb-10">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }} // Animation triggers only once
          variants={variants}
        >
          <section className="h-full rounded-[10px] w-[350px] md:w-[350px] lg:w-[400px] min-h-[490px] gap-10 py-10 shadow-[0px_4px_10.3px_#00000040] flex flex-col items-center border duration-200 hover:scale-[1.01] border-[#C7C7C7] text-center text-black font-bold">
            <h2 className="text-[40px]">
              {lang === "ar" ? "الخطة المجانية" : "Free plan"}
            </h2>
            <ul className="flex flex-col gap-3">
              {Object.values(Free).map((e, i) => (
                <li
                  className={`text-[20px] flex items-center gap-2 ${
                    lang === "ar" ? "flex-row" : "flex-row-reverse"
                  }`}
                  key={i}
                >
                  {e}
                  <CircleCheck />
                </li>
              ))}
            </ul>
            <h3>{lang === "ar" ? "مجاناً" : "Free"}</h3>
          </section>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }} // Animation triggers only once
          variants={variants}
        >
          <section className="h-full rounded-[10px] w-[350px] md:w-[350px] lg:w-[400px] min-h-[490px] gap-10 py-10 shadow-[0px_4px_10.3px_#00000040] flex flex-col items-center OurPlansGrident duration-200 hover:scale-[1.01] text-white">
            <h2 className="text-[40px]">
              {lang === "ar" ? "الخطة المميزة" : "Premium plan"}
            </h2>
            <ul className="flex flex-col gap-3">
              {Object.values(Premium).map((e, i) => (
                <li
                  className={`text-[20px] flex items-center gap-2 ${
                    lang === "ar" ? "flex-row" : "flex-row-reverse"
                  }`}
                  key={i}
                >
                  {e}
                  {cheak}
                </li>
              ))}
            </ul>
            <h3>{lang === "ar" ? "20 ريال / شهر" : "20 riyals / month"}</h3>
            <Link
              to={!auth && "/singnin"}
              onClick={auth && subscribe}
              className="bg-white text-black text-[20px] font-bold w-[229px] h-[45px] flex justify-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] items-center p-[10px] rounded-[10px]"
            >
              {lang === "ar" ? "إشترك الآن" : "Subscribe now"}
            </Link>
          </section>
        </motion.div>
      </div>
    </section>
  );
}

function JoinUsLastSection() {
  const { lang } = useLang();
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);

  const leftAnimation = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const rightAnimation = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  // Check if the section is in the viewport
  const isInViewport = (element) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isInViewport(sectionRef.current) && !hasAnimated) {
        setHasAnimated(true); // Set animation state to true when in viewport
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Check if in viewport on mount
    handleScroll();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasAnimated]); // Dependency on hasAnimated

  return (
    <section
      ref={sectionRef}
      className={`relative flex container md:flex-row flex-col mx-auto`}
    >
      <div
        style={{
          boxShadow: "#4DBDF7 0px 10px 210px 203px",
        }}
        className="rounded-full -z-10 top-1/2 -translate-y-1/2 absolute -translate-x-1/2 left-1/2 w-[0px] h-[0px]"
      />
      {/* Left Section Animation */}
      <motion.section
        initial="hidden"
        animate={hasAnimated ? "visible" : "hidden"}
        variants={leftAnimation}
        transition={{ duration: 0.5 }}
        className="relative mx-auto w-1/2"
      >
        <div
          style={{
            boxShadow: "#4DBDF7 0px 10px 210px 203px",
          }}
          className="rounded-full -z-10 top-1/2 -translate-y-1/2 absolute left-1/2 -translate-x-1/2 w-[0px] h-[0px]"
        />
        <img
          src="/landingpage/robotlastsection.png"
          className="object-cover mx-auto"
          alt=""
        />
      </motion.section>

      {/* Right Section Animation */}
      <motion.section
        initial="hidden"
        animate={hasAnimated ? "visible" : "hidden"} // Animate if hasAnimated is true
        variants={rightAnimation}
        transition={{ duration: 0.5 }}
        className="relative w-full md:w-1/2 flex flex-col gap-10"
      >
        <div
          style={{
            boxShadow: "#4DBDF7 0px 10px 210px 203px",
          }}
          className="rounded-full -z-10 top-1/2 -translate-y-1/2 absolute right-52 w-[0px] h-[0px]"
        />
        <div
          className={`${
            lang === "ar"
              ? "text-right text-[30px] md:text-[55px]"
              : "text-left text-[20px] md:text-[30px]"
          } font-bold`}
        >
          {lang === "ar"
            ? "لا تتردد في اتخاذ الخطوة الأولى نحو نجاح مشروعك"
            : "Don't hesitate to take the first step towards the success of your project."}
        </div>
        <Link
          to={"/signup"}
          className="flex justify-center items-center mx-auto w-[295px] h-[60px] 
          hover:border-[#060054] border border-white hover:bg-white bg-[#060054] 
          hover:text-[#060054] duration-300 text-white font-bold text-[29px] 
          rounded-[18px] shadow-[0px_4.11px_14.06px_0px_#00000091]"
        >
          {lang === "ar" ? "!إنضم إلينا الآن" : "Join us now!"}
        </Link>
      </motion.section>
    </section>
  );
}
