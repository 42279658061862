import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Rect, Image } from "react-konva";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom/client";
const useKonvaWrapper = () => {
  const konvaRef = useRef(null);
  const [jsxElements, setJsxElements] = useState([]);
  const [helpers, setHelper] = useState([]);
  const wrapperRef = useRef(null);

  // Function to add a draggable Konva Rect
  const addRectangle = () => {
    const layer = konvaRef.current;
    if (layer) {
      const rect = new window.Konva.Rect({
        width: 100,
        height: 100,
        fill: "red",
        draggable: true,
      });

      layer.add(rect);
      layer.draw(); // Redraw the layer after adding the new element
    }
  };

  const addJsxComponent = (component) => {
    // Create a temporary div to measure the component
    const tempDiv = document.createElement("div");

    // Set styles to ensure the component renders correctly for measurement
    tempDiv.style.position = "absolute";
    tempDiv.style.visibility = "hidden"; // Hide it but render it for size calculations
    tempDiv.style.pointerEvents = "none"; // Ensure it doesn't interfere with UI
    tempDiv.style.top = "0";
    tempDiv.style.left = "0";

    document.body.appendChild(tempDiv);

    // Render the JSX component into the temporary div using React 18's createRoot
    const root = ReactDOM.createRoot(tempDiv);
    root.render(component);

    // Use requestAnimationFrame to ensure layout is completed before measurement
    requestAnimationFrame(() => {
      // Use getBoundingClientRect to get the dimensions after render
      const { width, height } = tempDiv.getBoundingClientRect();
      console.log(width, height);

      // Clean up by unmounting and removing the temporary div
      root.unmount();
      document.body.removeChild(tempDiv);

      // Create a new element object with the component and its dimensions
      const newElement = {
        id: jsxElements.length,
        component,
        x: 0,
        y: 0,
        width,
        height,
      };

      // Update the state with the new element and its helper
      setJsxElements([...jsxElements, newElement]);
      setHelper([
        ...helpers,
        {
          id: jsxElements.length,
          x: 0,
          y: 0,
          width: width,
          height: height,
        },
      ]);
    });
  };

  // Function to handle drag movement for JSX elements
  const handleDragMove = (e, id) => {
    const { x, y } = e.target.position();
    // console.log(x, y);
    let elements = [...jsxElements];
    let helpersHolder = [...helpers];
    let idx = elements.findIndex((ele) => ele.id === id);
    // console.log(idx, id, elements);
    if (idx > -1) {
      elements[idx].x = x;
      elements[idx].y = y;
      helpersHolder[idx].x = x;
      helpersHolder[idx].y = y;
      setJsxElements(elements);
      setHelper(helpersHolder);
    }
  };
  const getAllHtmlElements = () => {
    let docs = [];
    for (let ele of jsxElements) {
      const doc = document.getElementById(`${ele.id}-html`);
      docs.push(doc);
    }
    return docs;
  };
  // Canvas rendering JSX components inside Konva Stage
  const Canvas = ({ width, height }) => {
    return (
      <div
        ref={wrapperRef}
        id="canvas-container"
        style={{ position: "relative", width: width, height: height }}
      >
        <Stage width={width} height={height}>
          <Layer ref={konvaRef}>
            {helpers.map(({ id, x, y, height, width }) => (
              <Rect
                onDragEnd={(el) => handleDragMove(el, id)}
                key={id}
                x={x + 1}
                y={y + 1}
                width={width}
                height={height}
                src={"/moveIcon.png"}
                // fill={"red"}
                stroke={"black"}
                draggable
              />
            ))}
          </Layer>
        </Stage>
        <div
          style={{ height, width }}
          className="absolute left-0 top-0 pointer-events-none border-2"
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width,
              height,
              pointerEvents: "none",
            }}
            id="pdfContainer"
          >
            {jsxElements.map(({ id, component, x, y }) => (
              <div
                key={id}
                id={`${id}-html`}
                style={{
                  position: "absolute",
                  top: y,
                  left: x,
                  pointerEvents: "none",
                }}
              >
                {component}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return { Canvas, addRectangle, addJsxComponent, getAllHtmlElements };
};
const SVGImage = ({ src, ...props }) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const loadImage = () => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => {
        setImage(img);
      };
    };
    loadImage();
  }, [src]);

  return <Image {...props} ref={imageRef} image={image} draggable />;
};
export default useKonvaWrapper;
