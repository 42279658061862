/* eslint-disable react/prop-types */
import { createContext, useContext, useEffect, useState } from "react";
// import { io } from "socket.io-client";
export const AuthContext = createContext();
export const useAuth = () => {
	return useContext(AuthContext);
};

export const setToken = (token, account) => {
	const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
	localStorage.setItem("authToken", token);
	localStorage.setItem("account", JSON.stringify(account));
	localStorage.setItem("tokenExpiration", expirationTime);
};

export const getToken = () => {
	const token = localStorage.getItem("authToken");
	const account = localStorage.getItem("account");
	const expirationTime = localStorage.getItem("tokenExpiration");

	if (!token || !expirationTime) {
		return null;
	}

	const currentTime = new Date().getTime();
	if (currentTime > expirationTime) {
		removeToken();
		return null;
	}
	return {
		token: localStorage.getItem("authToken"),
		account: localStorage.getItem("account"),
	};
};

export const removeToken = (nav = () => {}) => {
	localStorage.removeItem("authToken");
	localStorage.removeItem("account");
	localStorage.removeItem("tokenExpiration");
	nav("/");
};

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState(null);
	const [account, setAccount] = useState(null);
	const [search, setSearch] = useState();

	// const socket = io("https://seashell-app-fxoft.ondigitalocean.app", {
	// 	auth: { token: auth },
	// 	reconnectionAttempts: 5,
	// 	reconnectionDelay: 1000,
	// });

	// useEffect(() => {
	// 	if (!socket || !account) return;
	// 	socket.emit("joinUser", account._id);
	// }, [socket, account]);
	// const reconnectSocket = () => {
	// 	if (socket && socket.disconnected) {
	// 		socket.connect();
	// 		console.log(socket, "socket");
	// 	}
	// };

	function getNestedFieldValue(record, field) {
		return field.split(".").reduce((acc, part) => acc && acc[part], record);
	}
	useEffect(() => {
		console.log(search, "search");
	}, [search]);
	function searchRecords(records, searchFields, searchInput) {
		searchInput = search;
		if (!searchInput) return records;

		const normalizedSearchInput = String(searchInput).toLowerCase();

		return records.filter((record) =>
			searchFields.some((field) => {
				const fieldValue = getNestedFieldValue(record, field);
				return (
					fieldValue !== undefined &&
					fieldValue !== null &&
					String(fieldValue).toLowerCase().includes(normalizedSearchInput)
				);
			})
		);
	}

	useEffect(() => {
		const tokenData = getToken();
		if (tokenData) {
			const { token, account } = tokenData;
			setAuth(token);
			setAccount(JSON.parse(account));
		}
	}, []);

	const logout = () => {
		removeToken();
		setAuth(null);
		setAccount(null);
	};

	return (
		<AuthContext.Provider
			value={{
				auth: auth || null,
				setAuth,
				account: account || null,
				setAccount,
				search,
				setSearch,
				searchRecords,
				setToken,
				logout,
				// socket,
				// reconnectSocket,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
