import { LangText } from "../../../language/LangElements";
import GridentBorders from "../../components/BorderGrident";
import { useLang } from "../../../Hooks/LangContext";
import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/authContext";
import authApi from "../../../apis/Auth";
import { useSnackbar } from "../../../Hooks/SnackBar";

export default function BasicInfo() {
	const { lang } = useLang();
	const { account, auth } = useAuth();
	const { openSnackbar } = useSnackbar();
	const formatDate = (isoString) => {
		return isoString.split("T")[0];
	};
	const [basicDataEdit, setBasicDataEdit] = useState(false);
	const [basicInfoCollectedData, setBasicInfoCollectedData] = useState({
		firstname: account?.firstname || "first name",
		lastname: account?.lastname || "last name",
		birth: formatDate(account?.birth),
		email: "",
		gender: account?.gender || "male",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setBasicInfoCollectedData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (e.target.checkValidity()) {
			// Proceed with submission if all fields are valid
			console.log("Form submitted:", basicInfoCollectedData);
			setBasicDataEdit(false);
			authApi
				.updateUserInfo(auth, basicInfoCollectedData)
				.then((res) => {
					console.log(res);
					openSnackbar(
						lang === "ar"
							? "تم تحديث البيانات بنجاح"
							: "Data updated successfully"
					);
				})
				.catch((err) => {
					console.log(err);
					openSnackbar(
						err.response.data.msg === "this email is already taken" &&
							lang === "ar"
							? "هذا البريد الالكتروني مستخدم بالفعل"
							: "this email is already taken"
					);
				});
		} else {
			// If form is invalid, the browser will show validation errors
			console.log("Form contains invalid data.");
		}
	};

	useEffect(() => {
		console.log(basicInfoCollectedData);
	}, [basicInfoCollectedData]);

	return (
		<form
			onSubmit={handleSubmit}
			className={`${
				basicDataEdit && "bg-[#EEEEEE]"
			} flex flex-col gap-10 rounded-[10px] p-5`}
		>
			<div
				className={`flex ${
					lang === "ar" ? "md:flex-row-reverse" : "md:flex-row"
				} justify-between flex-col md:gap-0 gap-10 items-center`}
			>
				<LangText
					className={"text-black text-[35px] font-bold "}
					ar="البيانات الأساسية"
					en="Basic data"
				/>
				{!basicDataEdit && (
					<GridentBorders
						className={"cursor-pointer"}
						gridentStyle={"90deg, #4DBDF7 0%, #9A9DFE 100%"}
						resetColor={"bg-white  !justify-end "}
						rounded={"10px"}
						strockWidth={"1px"}
						onClick={() => {
							setBasicDataEdit(true);
						}}
					>
						<button
							type="button" // Button should not submit the form in edit mode
							className="w-[135px] h-[43.78px] p-[6.89px] rounded-[6.89px] flex justify-center items-center 
              text-black text-[21px] font-bold"
						>
							{lang === "ar" ? "تعديل" : "Edit"}
						</button>
					</GridentBorders>
				)}
			</div>
			<div
				className={` ${
					lang === "ar" ? "md:flex-row-reverse" : "md:flex-row"
				} flex flex-col  justify-between items-center gap-10 md:gap-0 md:items-end`}
			>
				<div
					className={`focus:[&>ul>li>input]:border focus:[&>ul>li>input]:outline-none
          [&>ul>li>input]:px-[9px] [&>ul>li>input]:py-1 
          [&>ul>li>input]:rounded-[10px] [&>ul>li>input]:font-bold [&>ul>li>input]:text-[17px]
          [&>ul>li>input]:w-full [&>ul]:flex-col  [&>ul>li>label]:font-bold
          [&>ul]:flex [&>ul]:gap-8 ${
						lang === "ar"
							? "[&>ul]:md:flex-row-reverse [&>ul>li>input]:text-right [&>ul>li>input]:placeholder:text-right"
							: "[&>ul]:md:flex-row [&>ul>li>input]:text-left [&>ul>li>input]:placeholder:text-left"
					} [&>ul>li]:flex-col  [&>ul>li]:gap-1 [&>ul>li]:flex flex flex-col gap-10
          ${
						!basicDataEdit &&
						"[&>ul>li>input]:border [&>ul>li>input]:border-[#EAEAEA]"
					}
          
          `}
				>
					<ul>
						<li>
							<label htmlFor="firstname">
								<LangText ar="الأسم الأول" en="first name" />
							</label>
							<input
								onChange={handleInputChange}
								value={basicInfoCollectedData?.firstname}
								disabled={!basicDataEdit}
								type="text"
								name="firstname"
								id="firstname"
							/>
						</li>
						<li>
							<label htmlFor="lastname">
								<LangText ar="الأسم الأخير" en="last name" />
							</label>
							<input
								onChange={handleInputChange}
								value={basicInfoCollectedData?.lastname}
								disabled={!basicDataEdit}
								type="text"
								name="lastname"
								id="lastname"
							/>
						</li>
					</ul>
					<ul>
						<li>
							<label htmlFor="email">
								<LangText ar="البريد الإلكتروني" en="e-mail" />
							</label>
							<input
								onChange={handleInputChange}
								value={basicInfoCollectedData.email}
								disabled={!basicDataEdit}
								type="email" // This ensures email format validation
								name="email"
								id="email"
							/>
						</li>
						<li>
							<label htmlFor="birth">
								<LangText ar="تاريخ الميلاد" en="birth" />
							</label>
							<input
								onChange={handleInputChange}
								value={basicInfoCollectedData.birth}
								className=" w-full lg:min-w-[252px]"
								disabled={!basicDataEdit}
								type="date"
								name="birth"
								id="birth"
							/>
						</li>
						<li className="relative">
							<label htmlFor="gender">
								<LangText ar="النوع" en="gender" />
							</label>
							<select
								onChange={handleInputChange}
								value={basicInfoCollectedData.gender}
								disabled={!basicDataEdit}
								className={`${
									!basicDataEdit && "border border-[#EAEAEA]"
								} appearance-none indent-1 overflow-hidden w-full lg:w-[252px] focus:border focus:outline-none
                px-[9px] py-1 ${lang === "ar" ? "text-right" : "text-left"}
                rounded-[10px] font-bold text-[17px]`}
								name="gender"
								id="gender"
							>
								<option value="male">{lang === "ar" ? "ذكر" : "male"}</option>
								<option value="female">
									{lang === "ar" ? "أنثى" : "female"}
								</option>
							</select>
						</li>
					</ul>
				</div>
				{basicDataEdit && (
					<button
						type="submit" // Use type="submit" here to trigger the form's validation
						className="w-[135px] h-[43.78px] p-[6.89px] rounded-[6.89px] flex justify-center items-center 
            bg-gradient-to-bl from-[#4DBDF7] from-[6.97%] to-[#9A9DFE] to-[71.22%]
            text-white text-[21px] font-bold"
					>
						{lang === "ar" ? "تأكيد" : "Confirm"}
					</button>
				)}
			</div>
		</form>
	);
}
