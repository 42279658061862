import { MapPin, CalendarFold, Trash2, ArrowDownToLine } from "lucide-react";
import { useLang } from "../../../Hooks/LangContext";
import { Spot, Area } from "../../../svgIcons/FeasibilityStudiesIcons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/authContext";
export function Card({ data }) {
  const { lang } = useLang();
  const{auth} = useAuth()
  const navigate = useNavigate();
  return (
    <article
      className="bg-[#f4f4f4] duration-200 hover:scale-[1.01] 
    shadow-[4px_4px_17.5px_0px_#00000040] w-full md:w-[300px] h-[490px] 
    overflow-hidden rounded-[10px] "
    >
      <div className="w-full">
        <img
          src={data?.images[0] ? data?.images[0] : "/images/cardimg.png"}
          className="object-cover w-full md:w-[368px] h-[261px]  rounded-[10px]"
          alt=""
        />
      </div>
      <h2 className="text-[#311616] h-[60px] flex items-center justify-center my-2 font-bold text-[20px] text-center">
        {data?.data["العنوان"]}
      </h2>
      <div className="bg-[#ebebeb] h-full rounded-b-[10px]">
        <ul className="[&>li>p]:text-[15px] [&>li>p]:font-bold [&>li]:flex [&>li]:justify-end flex flex-col gap-1 pt-2 [&>li]:items-center px-2   [&>li]:gap-2    text-right ">
          <li>
            <p>{"الموقع : " + data.data["الموقع"]}</p>
            <MapPin size={15} />
          </li>
          <li>
            <p>المساحة : 527 م²</p>
            {Area}
          </li>
          <li>
            <p>{"نوع المشروع : " + "تطوير سكني فردي"}</p>
          </li>
          <li>
            {/* <p>{"تم إنشاء الدراسة في : " + data?.createdAt.split("T")[0]}</p> */}
            {Spot}
          </li>
        </ul>
        <div className="flex items-center mt-4 justify-around">
          {/* <button className="p-[5px] duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] rounded-[5.09px] bg-[#BC0000] text-white">
            <Trash2 size={17} />
          </button>
          <button className="bg-[#FFFFFF] duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] p-[5px] rounded-[5.09px]">
            <ArrowDownToLine size={17} />
          </button> */}
          <button
            onClick={() => {
              navigate(auth?"/FeasibilityStudies":"/singnin", {
                // state: {
                //   apiResponse: data.data,
                //   mapUrl: data.mapImage,
                //   images: data.images,
                //   blockProps: {
                //     blockId: "",
                //     landId: "",
                //   },
                // },
              });
            }}
            className="bg-gradient-to-t font-bold rounded-[5.09px]
              flex justify-center items-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] w-[204px] h-[28px] p-[5.09px]
              from-[#1e3a47] to-[#9A9DFE]"
          >
            {lang === "ar" ? "عرض تفاصيل المشروع" : "View project details"}
          </button>
        </div>
      </div>
    </article>
  );
}
