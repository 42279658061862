import { useState } from "react";
import axios from "axios";

const useGeneratePDF = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generatePDF = async (dataObject, images, mapUrl) => {
    setIsLoading(true);
    setError(null);

    const data = JSON.stringify({ data: dataObject, images, mapUrl });

    const config = {
      method: "post",
      url: `${`https://inv-holder.vercel.app/api/`}pdf/generate-pdf`, // Replace with your API endpoint
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      responseType: "blob", // Important to receive the PDF as a blob
    };

    try {
      const response = await axios.request(config);

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "generated-report.pdf"; // Set the filename
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(link.href);
    } catch (err) {
      setError(err.message || "An error occurred while generating the PDF.");
    } finally {
      setIsLoading(false);
    }
  };

  return { generatePDF, isLoading, error };
};

export default useGeneratePDF;
