import { useEffect, useState } from "react";
import { useLang } from "../../Hooks/LangContext";
import { LangText } from "../../language/LangElements";
import { FaFacebookF } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { LoaderCircle } from "lucide-react";
import authApi from "../../apis/Auth";
import { useSnackbar } from "../../Hooks/SnackBar";

export default function SignUp() {
	const { setLang, lang } = useLang("ar");
	const nav = useNavigate();
	const { openSnackbar } = useSnackbar();
	function changeLang() {
		setLang(lang === "ar" ? "en" : "ar");
	}

	const [signIn, setSignIn] = useState({
		email: "",
		password: "",
		fullName: "",
	});

	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSignInChange = (e) => {
		const { name, value } = e.target;
		setSignIn((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const validate = () => {
		const newErrors = {};
		if (!signIn.email) {
			newErrors.email =
				lang === "ar" ? "البريد الإلكتروني مطلوب" : "Email is required";
		}
		if (!signIn.fullName) {
			newErrors.fullName =
				lang === "ar" ? "الاسم مطلوب" : "full name is required";
		}
		if (!signIn.password) {
			newErrors.password =
				lang === "ar" ? "كلمة المرور مطلوبة" : "Password is required";
		}
		return newErrors;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const validationErrors = validate();
		setIsSubmitting(true);
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			setIsSubmitting(false);
		} else {
			console.log("Form submitted:", signIn);
			authApi
				.signup(signIn)
				.then((res) => {
					console.log(res);
					setIsSubmitting(false);
					openSnackbar(
						lang === "ar" ? "تم التسجيل بنجاح" : "Signed up successfully"
					);
					nav("/");
				})
				.catch((err) => {
					console.log(err);
					openSnackbar(lang === "ar" ? "حدث خطأ ما" : "An error occurred");
					setIsSubmitting(false);
				});
			// setTimeout(() => {
			// }, 2000);
		}
	};
	return (
		<div className="flex relative min-h-screen">
			<div
				onClick={changeLang}
				className="text-white absolute cursor-pointer left-10 top-10 rounded-full bg-black w-7 h-7 flex justify-center items-center"
			>
				{lang === "ar" ? "ع" : "en"}
			</div>
			<form
				onSubmit={handleSubmit}
				action=""
				className="w-full md:w-1/2 flex   shadow-md justify-center items-center py-24"
			>
				<div className="w-[350px]">
					<div>
						<img src="/deal.png" className="object-cover" alt="" />
					</div>
					<ul
						className="flex flex-col 
            font-semibold
            gap-5
        [&>li>input]:border [&>li>input]:border-black focus:[&>li>input]:outline-none
        [&>li>input]:w-full [&>li>input]:rounded-lg [&>li>input]:p-2
        [&>li>label]:block [&>li>label]:mb-2
        "
					>
						<li>
							<label htmlFor="name">
								<LangText ar="الاسم كامل" en="full name" />
							</label>
							<input
								onChange={handleSignInChange}
								type="text"
								id="name"
								name="fullName"
								value={signIn.fullName}
								required
								placeholder="Full Name"
							/>
							{errors.fullName && (
								<p
									className={`text-red-500 text-[12px] ${
										lang === "ar" ? "text-left" : "text-right"
									}`}
								>
									{errors.fullName}
								</p>
							)}
						</li>
						<li>
							<label htmlFor="email">
								<LangText ar="بريد إلكتروني" en="email" />
							</label>
							<input
								onChange={handleSignInChange}
								type="email"
								id="email"
								name="email"
								value={signIn.email}
								required
								placeholder="email"
							/>
							{errors.email && (
								<p
									className={`text-red-500 text-[12px] ${
										lang === "ar" ? "text-left" : "text-right"
									}`}
								>
									{errors.email}
								</p>
							)}
						</li>
						<li>
							<label htmlFor="password">
								<LangText ar="كلمه السر" en="password" />
							</label>
							<input
								onChange={handleSignInChange}
								type="password"
								id="password"
								name="password"
								value={signIn.password}
								required
								placeholder="password"
							/>
							{errors.password && (
								<p
									className={`text-red-500 text-[12px] ${
										lang === "ar" ? "text-left" : "text-right"
									}`}
								>
									{errors.password}
								</p>
							)}
						</li>
						<li>
							<button
								onClick={handleSubmit}
								disabled={isSubmitting}
								className={`p-2 rounded-lg bg-[#9d9dff] w-full flex justify-center items-center gap-2 text-white ${
									lang === "ar" ? "flex-row-reverse" : ""
								}`}
							>
								<LangText
									ar={
										isSubmitting
											? "جاري التسجيل"
											: "تسجيل الدخول بالبريد الالكتروني"
									}
									en={isSubmitting ? "submitting" : "submit"}
								/>
								{isSubmitting && (
									<>
										<span className="animate-spin flex justify-center items-center cursor-not-allowed">
											<LoaderCircle />
										</span>
									</>
								)}
							</button>
						</li>
						<li className="relative">
							<div className="bg-white w-fit absolute left-1/2 bottom-1/2  translate-y-1/2 -translate-x-1/2 px-2">
								تابع بأستخدام
							</div>
							<div className="w-full bg-black h-[1px] rounded-full" />
						</li>
						{/* <li>
              <div className="cursor-pointer bg-[#1a75cf] w-full flex justify-center gap-1 items-center text-white rounded-lg p-2">
                {lang === "ar"
                  ? "تسجيل الدخول بواسطه فيسبوك"
                  : "Login with Facebook"}
                <FaFacebookF />
              </div>
            </li> */}
						<li className="">
							<div className="cursor-pointer  rounded-lg border border-black w-full justify-center flex gap-1 items-center text-black p-2">
								{lang === "ar"
									? "تسجيل الدخول بواسطه جوجل"
									: "Login with google"}
								<img src="/google.png" alt="" />
							</div>
						</li>
						<li>
							<Link className="underline" to={"/"}>
								<LangText
									className={"justify-center text-[15px]"}
									ar="هل لديك حساب؟تسجيل الدخول"
									en="Do you have an account? Log in"
								/>
							</Link>
						</li>
					</ul>
				</div>
			</form>
			<div className="md:block hidden w-1/2 h-[800px]">
				<img
					src="/loginpic.png"
					className="object-cover w-full h-full"
					alt=""
				/>
			</div>
		</div>
	);
}
