export const getImagePrompt = ({
  type,
  floors,
  appartments,
  landArea,
  city,
  neighborhood,
  propertyType,
  proposedFloors,
  apartmentsPerFloor,
  customPropertyType,
}) => {
  const getAngleDescription = () => {
    switch (type) {
      case "سكني":
        return "an elevated and wide-angle perspective, showcasing the building and the entire land area, including surrounding streets, gardens, and open spaces, ensuring the property boundaries are clearly visible.";
      case "تجاري":
        return "a distant, elevated view capturing the entire commercial building along with the land area, including clear property boundaries and adjacent streets, providing a sense of how the building utilizes the available land.";
      case "تجاري سكني":
        return "an aerial perspective that highlights both the mixed-use building and the land it occupies, clearly showing how the retail and residential spaces are distributed across the land area, with surrounding streets and landscapes visible.";
      case "مول تجاري":
        return "a high, wide-angle view that captures the full extent of the shopping mall and its land area, including the parking lots, surrounding streets, and the open spaces around the mall.";
      case "فلل":
        return "a ground-level, wide-angle view that focuses on the luxurious villas and their private land areas, showing the exact boundaries of each property, including gardens, pools, and surrounding shared landscapes.";
      case "فيلا":
        return "a distant, elevated view that emphasizes the full extent of the standalone villa’s land area, including its landscaped gardens, pool, and surrounding greenery, providing a clear view of the property boundaries.";
      case "مجمع سكني":
        return "an aerial perspective that captures the entire residential complex and the land it spans, showing the property’s layout, communal spaces, and how the apartment buildings are distributed within the land area.";
      case "إداري":
        return "an elevated, formal view that captures the administrative building and its land area, showing clear boundaries, adjacent streets, and how the land is utilized for parking and public spaces around the building.";
      case "برج تجاري سكني":
        return "an elevated view capturing both the commercial and residential components of the tower, with a focus on the land area, showing the street-level retail spaces and upper residential units, while clearly defining the boundaries of the entire property.";
      case "برج تجاري إداري":
        return "an aerial view showcasing the administrative and commercial aspects of the tower, clearly defining the land area and surrounding streets, emphasizing the building’s dual purpose and its integration within the urban landscape.";
      case "برج إداري":
        return "an elevated perspective that highlights the formal design of the administrative tower, with clear visibility of the entire land area, adjacent streets, and parking facilities.";
      case "فندقي":
        return "a high, wide-angle perspective that showcases the hotel building and its expansive land area, including amenities such as pools, gardens, and outdoor seating, while clearly displaying the property boundaries and surrounding environment.";
      case "أخرى":
        return "a creative, wide-angle aerial view that focuses on the unique building and its land area, showing the exact layout of the property within its surroundings, emphasizing innovative use of the land.";
      default:
        return "an elevated view capturing the entire building and land area, showing the property boundaries and how the structure is positioned within the land.";
    }
  };

  const angleDescription = getAngleDescription();

  if (type === "سكني") {
    return `a 3D rendered image of a residential building consisting of ${floors} floors and ${appartments} units per floor, with a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should highlight modern, sustainable living with spacious balconies, green roofs, and energy-efficient systems. The surrounding streets and communal areas like gardens and playgrounds should also be visible, with clear visibility of the exact land area. The image should be ${angleDescription}.`;
  }
  if (type === "تجاري") {
    return `a 3D rendered image of a commercial building consisting of ${floors} floors and ${appartments} units per floor, with a land area of: ${landArea}, located in ${city}, ${neighborhood}. The building design should feature sleek, contemporary architecture with glass facades and advanced facilities. The surrounding street scene with people and vehicles should depict the bustling commercial environment, and the land area boundaries should be clearly visible. The image should be ${angleDescription}.`;
  }
  if (type === "تجاري سكني") {
    return `a 3D rendered image of a mixed-use building combining retail spaces on the lower floors with residential units above, with ${floors} floors and ${appartments} units per floor, and a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should smoothly integrate commercial and living spaces, with rooftop gardens and fitness centers. The surrounding urban area should be visible, and the exact layout of the land area should be emphasized. The image should be ${angleDescription}.`;
  }
  if (type === "مول تجاري") {
    return `a 3D rendered image of a large shopping mall with ${floors} floors and ${appartments} units per floor, and a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should feature expansive glass storefronts and a spacious layout. Include the surrounding street bustling with people, showcasing the mall as a social hub, and the land area boundaries should be clearly defined. The image should be ${angleDescription}.`;
  }
  if (type === "فلل") {
    return `a 3D rendered image of a cluster of luxurious villas in a gated community, each with ${floors} floors, and a land area of: ${landArea}, located in ${city}, ${neighborhood}. The villas should feature private gardens and pools. The surrounding shared amenities and landscapes should also be visible, with clear views of the individual land plots. The image should be ${angleDescription}.`;
  }
  if (type === "فيلا") {
    return `a 3D rendered image of a standalone luxury villa with ${floors} floors and a land area of: ${landArea}, located on the outskirts of ${city} in ${neighborhood}. The villa should have a blend of modern and traditional architectural elements, with landscaped gardens and a private pool. The surrounding rural or suburban street should also be visible, and the exact land area should be highlighted. The image should be ${angleDescription}.`;
  }
  if (type === "مجمع سكني") {
    return `a 3D rendered image of a residential complex with multiple apartment buildings, each with ${floors} floors and ${appartments} units per floor, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The complex should include communal spaces like playgrounds and pools. The adjacent streets and neighborhood should be visible, with clear views of the full land area and how the buildings are distributed. The image should be ${angleDescription}.`;
  }
  if (type === "إداري") {
    return `a 3D rendered image of an administrative building with ${floors} floors and ${appartments} units per floor, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The architecture should be formal and secure. The surrounding urban environment and streets should be visible, and the land area boundaries should be clearly defined. The image should be ${angleDescription}.`;
  }
  if (type === "برج تجاري سكني") {
    return `a 3D rendered image of a residential-commercial tower with ${floors} floors, combining retail spaces on the lower floors and residential units above, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should emphasize the seamless integration of commercial and living spaces. The land area should be clearly visible, showing the boundaries and surrounding urban features. The image should be ${angleDescription}.`;
  }
  if (type === "برج تجاري إداري") {
    return `a 3D rendered image of a commercial-administrative tower with ${floors} floors, combining retail spaces and offices, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should highlight both the commercial and formal aspects of the building, with clear views of the land area and surrounding streets. The image should be ${angleDescription}.`;
  }
  if (type === "برج إداري") {
    return `a 3D rendered image of an administrative tower with ${floors} floors, featuring a formal, professional design, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should showcase modern office spaces and parking facilities, with clear visibility of the land area and adjacent streets. The image should be ${angleDescription}.`;
  }
  if (type === "فندقي") {
    return `a 3D rendered image of a hotel with ${floors} floors, featuring amenities such as pools, gardens, and outdoor seating areas, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should emphasize luxury and comfort, with clear visibility of the hotel's layout and property boundaries, as well as the surrounding environment. The image should be ${angleDescription}.`;
  }
  if (type === "أخرى") {
    return `a 3D rendered image of a unique ${customPropertyType} building with ${floors} floors and ${appartments} units per floor, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should showcase innovative materials and sustainability. The surrounding streets and neighborhood context should be visible, with a focus on the land area’s layout. The image should be ${angleDescription}.`;
  } else {
    return `a 3D rendered image of a unique ${type} building with ${floors} floors and ${appartments} units per floor, on a land area of: ${landArea}, located in ${city}, ${neighborhood}. The design should showcase innovative materials and sustainability. The surrounding streets and neighborhood should be visible, with the land area boundaries clearly highlighted. The image should be ${angleDescription}.`;
  }
};
