import React from "react";
// import useKonvaWrapper from "./konva";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import useKonvaWrapper from "../../konva";
const PdfApp = () => {
  const { Canvas, addRectangle, addJsxComponent, getAllHtmlElements } =
    useKonvaWrapper();

  return (
    <div className="fle-1">
      {/* <div id="lolol">عربي</div> */}
      <div className="flex flex-row gap-5">
        {/* <button onClick={addRectangle}>Add Rectangle</button> */}
        <button onClick={() => addJsxComponent(<div>اي جااامد</div>)}>
          Add jsx
        </button>
        <button
          onClick={() =>
            addJsxComponent(
              <div className="bg- text-white h-40 w-40">
                <img src={`/logo192.png`} />
              </div>
            )
          }
        >
          Add photo
        </button>
        <button
          onClick={() => {
            const input = document.getElementById("pdfContainer"); // Use a container that wraps your content
            const opt = {
              margin: [10, 10, 10, 10], // Add some margin
              filename: "download.pdf",
              image: { type: "png", quality: 1 },
              html2canvas: {
                scale: 2,
                useCORS: true, // Enable CORS for image loading
              },
              jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
            };

            // Use html2pdf to generate the PDF from the container
            html2pdf().from(input).set(opt).save();
          }}
        >
          Download PDF
        </button>
      </div>

      <Canvas width={600} height={600} />
    </div>
  );
};

export default PdfApp;
