import { createContext, useContext, useState } from "react";

export const RefetchContext = createContext();
export const useRefetch = () => {
	return useContext(RefetchContext);
};

const RefetchProvider = ({ children }) => {
	const [refetch, setRefetch] = useState(false);

	return (
		<>
			<RefetchContext.Provider value={{ refetch, setRefetch }}>
				{children}
			</RefetchContext.Provider>
		</>
	);
};

export default RefetchProvider;
