import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMapContext } from "../../../Hooks/mapContext";
// import { useMapContext } from "../../Hooks/mapContext";
ChartJS.register(ArcElement, Tooltip, Legend);

const RecommendationsChart = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.type),
    datasets: [
      {
        label: "Land Use Recommendations",
        data: data.map((item) => item.accuracy.replace("%", "")),
        backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe"],
      },
    ],
  };

  return (
    <div>
      <Pie data={chartData} />
    </div>
  );
};

const AnalysisUI = ({ lang, data }) => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
      {/* Analysis Section */}
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Analysis of Land Area
      </h2>
      <div className="border-b border-gray-200 mb-6"></div>

      <div className="mb-6">
        <p className="text-gray-700 text-right">{data.analysisEn}</p>
      </div>

      {/* Recommendations Section */}
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Building Type Recommendations
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left text-gray-600">
                Building Type
              </th>
              <th className="py-2 px-4 text-left text-gray-600">Accuracy</th>
            </tr>
          </thead>
          <tbody>
            {data.numbers.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-200">
                  {item.type}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <div className="flex items-center">
                    <div className="w-full bg-gray-200 rounded h-4">
                      <div
                        className="bg-blue-500 h-4 rounded"
                        style={{ width: `${item.accuracy}` }}
                      ></div>
                    </div>
                    <span className="ml-2 text-gray-700">{item.accuracy}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LandAnalysis = ({ lang, photo }) => {
  const [data, setData] = useState(null); // To store the fetched data
  const [loading, setLoading] = useState(true); // To handle the loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://invesmentbackend.vercel.app/api/ai",
          {
            mapLink: photo,
          }
        );
        console.log(response, "response");
        console.log(response.data.recommendations);
        const recommendations = response.data.recommendations; // Parse JSON
        setData(recommendations); // Set the fetched data
        setLoading(false); // Data is loaded
      } catch (err) {
        setError(err.message);
        setLoading(false); // Error occurred
      }
    };

    fetchData(); // Fetch data on component mount
  }, [photo]);

  if (loading) return <p className="text-white text-2xl">Loading...</p>;
  if (error) return;

  return (
    <div
      style={{ height: "600px" }}
      className="w-[90%] h-[600px] overflow-hidden overflow-y-scroll bg-white flex flex-col justify-center items-center p-5 pt-[350px]  "
    >
      <div className="w-[260px] h-[260px]">
        <RecommendationsChart data={data.numbers} />
      </div>
      <div>
        <AnalysisUI lang={lang} data={data} />
      </div>
    </div>
  );
};

export default LandAnalysis;
