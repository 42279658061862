import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa6";
import { useLang } from "../../../Hooks/LangContext";
export default function LandingPageFooter() {
  const { lang } = useLang();
  return (
    <footer
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`${
        lang === "ar"
          ? "text-right lg:flex-row-reverse"
          : "lg:flex-row-reverse text-left"
      }   flex  bg-[#03002B] text-white 
        md:justify-between justify-center md:items-center
        items-start  w-full md:flex-col-reverse  lg:gap-0 gap-10 flex-col  font-bold text-[20.4px] py-5 md:py-16 px-10`}
    >
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 max-md:items-start"
      >
        <h2 className="md:mx-auto  md:h-[48.86px]">
          {lang === "ar" ? "تابعونا" : "follow us"}
        </h2>
        <div className="  gap-2 flex-row flex [&>button]:w-[39px] [&>button]:flex [&>button]:justify-center [&>button]:items-center [&>button]:h-[39px] [&>button]:rounded-full [&>button]:bg-[#b4b4b4]">
          <button className="hover:bg-[#1877F2] duration-300 hover:text-white">
            <FaFacebookF />
          </button>
          <button className="hover:bg-[#0077B5] duration-300 hover:text-white">
            <FaLinkedinIn />
          </button>
          <button className="hover:bg-[#1DA1F2] duration-300 hover:text-white">
            <FaTwitter />
          </button>
        </div>
      </aside>
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 max-md:items-start max-md:mt-4"
      >
        <h4 className="md:mx-auto">
          {lang === "ar" ? "روابط سريعة" : "Quick Links"}
        </h4>
        <div className="[&>a]:md:mx-auto text-[16px] font-medium text-nowrap flex max-md:flex-col lg:flex-row flex-col max-md:items-start gap-3">
          <Link className="group" to={""}>
            {lang === "ar" ? "من نحن" : "Who we are"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
          <Link className="group" to={""}>
            {lang === "ar" ? " الشروط والأحكام" : "Terms and Conditions"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
          <Link className="group" to={""}>
            {lang === "ar" ? "الخصوصية" : "Privacy"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
          <Link className="group" to={""}>
            {lang === "ar" ? " الاتصال والدعم" : "Contact & Support"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
          <Link className="group" to={""}>
            {lang === "ar" ? "اتفاقية الترخيص" : "License Agreement"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
          <Link className="group" to={""}>
            {lang === "ar" ? "خدماتنا" : "Our Services"}
            <div className="w-[0px] duration-300 group-hover:w-full bg-white h-[2px] rounded-full"></div>
          </Link>
        </div>
      </aside>
      <aside
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col gap-5 mt-4 max-md:items-start"
      >
        <Link to={"/"}>
          <img
            src="/landingpage/Real-Investmenthfooter.png"
            className="md:ml-auto md:object-cover w-[400.01px] md:h-[48.86px] max-md:h-[40px]"
            alt=""
          />
        </Link>
        <p
          dir={lang === "ar" ? "rtl" : "ltr"}
          className="text-[20px] font-bold text-[#767676]"
        >
          {lang === "ar"
            ? "اكتشف فرص الاستثمار العقاري على منصة فرصة"
            : "Discover real estate investment on the Forsah."}
        </p>
      </aside>
    </footer>
  );
}
