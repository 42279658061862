import axiosClient from "./axiosClient";

const getRemainingCases = async (token, id) => axiosClient.get(`studyCase/remainingcases/${id}`, { headers: { token: token } });

const getAllCases = async (token) => axiosClient.get(`studyCase/`, { headers: { token: token } });

const createStudyCase = async (data, token) => axiosClient.post(`studyCase/`, data, { headers: { token: token } });

const StudyCasesApi = {
  getRemainingCases,
  getAllCases,
  createStudyCase
}

export default StudyCasesApi;