import { LangText } from "../../../language/LangElements";
import { useLang } from "../../../Hooks/LangContext";
import { useEffect, useState } from "react";
import GridentBorders from "../../components/BorderGrident";
import { useAuth } from "../../../Hooks/authContext";
import { useSnackbar } from "../../../Hooks/SnackBar";
import authApi from "../../../apis/Auth";
import { LoaderCircle } from "lucide-react";

export default function PasswordEdit() {
	const { lang } = useLang();
	const { openSnackbar } = useSnackbar();
	const { auth, account } = useAuth();
	const [loading, setLoading] = useState(false);
	const [isEditPasswordOpen, setIsEditPasswordOpen] = useState(false);
	const [passwordDataInterd, setPasswordDataInterd] = useState({
		password: "",
		confirmpassword: "",
		currentpassword: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setPasswordDataInterd((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const form = e.target;
		if (form.checkValidity()) {
			// Proceed with submission if all fields are valid
			console.log("Form submitted:", passwordDataInterd);
			if (passwordDataInterd.password !== passwordDataInterd.confirmpassword) {
				openSnackbar(
					lang === "ar" ? "كلمة المرور غير متطابقة" : "Password not match"
				);
				setLoading(false);
				return;
			}
			setIsEditPasswordOpen(false);
			authApi
				.resetpassword(auth, {
					newPassword: passwordDataInterd.confirmpassword,
					oldPassword: passwordDataInterd.currentpassword,
				})
				.then((res) => {
					console.log(res);
					openSnackbar(
						lang === "ar"
							? "تم تحديث كلمة المرور بنجاح"
							: "Password updated successfully"
					);
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					openSnackbar(err.response.data.msg);
					setLoading(false);
				});
		} else {
			// If form is invalid, the browser will show validation errors
			console.log("Form contains invalid data.");
			Array.from(form.elements).forEach((input) => {
				if (!input.checkValidity()) {
					input.classList.add("error"); // Add an error class for custom styling
				} else {
					input.classList.remove("error"); // Remove error class if valid
				}
			});
		}
	};

	return (
		<>
			<section onSubmit={handleSubmit}>
				<hr className="w-full h-[1px] rounded-full bg-[#BABABA]" />

				{!isEditPasswordOpen && (
					<>
						<div
							className={`flex py-5 ${
								lang === "ar" ? "flex-row-reverse" : "flex-row"
							} justify-between items-center`}
						>
							<LangText
								className={"text-black text-[35px] font-bold"}
								ar="كلمة المرور"
								en="password"
							/>
							<GridentBorders
								className={"cursor-pointer"}
								gridentStyle={"90deg, #4DBDF7 0%, #9A9DFE 100%"}
								resetColor={"bg-white  !justify-end "}
								rounded={"10px"}
								strockWidth={"1px"}
							>
								<button
									type="button"
									onClick={() => {
										setIsEditPasswordOpen(true);
									}}
									className="p-[6.89px] rounded-[6.89px] flex justify-center items-center 
                          text-black text-[21px] font-bold"
								>
									{lang === "ar" ? "تعديل كلمة المرور" : "Edit password"}
								</button>
							</GridentBorders>
						</div>
						<hr className="w-full h-[1px] rounded-full bg-[#BABABA]" />
					</>
				)}
			</section>
			{isEditPasswordOpen && (
				<form
					className={`${"bg-[#EEEEEE]"} flex flex-col gap-10 rounded-[10px] p-5`}
				>
					<div>
						<LangText
							className={"text-black text-[35px] font-bold"}
							ar="كلمة المرور"
							en="password"
						/>
					</div>
					<div
						className={`${
							lang === "ar" ? "md:flex-row-reverse" : "md:flex-row"
						} flex flex-col  justify-between items-center gap-10 md:gap-0 md:items-end`}
					>
						<div
							className={`focus:[&>ul>li>input]:border-none focus:[&>ul>li>input]:outline-none
                    [&>ul>li>input]:px-[9px] [&>ul>li>input]:py-1 
                    [&>ul>li>input]:rounded-[10px] [&>ul>li>input]:font-bold [&>ul>li>input]:text-[17px]
                    [&>ul>li>input]:md:w-full
                    [&>ul]:flex-col 
                    [&>ul]:flex [&>ul]:gap-8 
                    [&>ul>li]:flex-col  [&>ul>li>label]:font-bold  [&>ul>li]:gap-2 [&>ul>li]:flex`}
						>
							<ul>
								<li>
									<label htmlFor="currentpassword">
										<LangText ar="كلمة المرور الحالية" en="Current Password" />
									</label>
									<input
										required
										onChange={handleInputChange}
										type="password"
										name="currentpassword"
										id="currentpassword"
										placeholder={
											lang === "ar"
												? "أدخل كلمة المرور الحالية"
												: "Enter current password"
										}
									/>
								</li>
								<li>
									<label htmlFor="password">
										<LangText ar="كلمة المرور الجديدة" en="New Password" />
									</label>
									<input
										required
										onChange={handleInputChange}
										type="password"
										name="password"
										id="password"
										minLength="8" // Minimum length for password
										placeholder={
											lang === "ar" ? "أدخل كلمة المرور" : "Enter New Password"
										}
									/>
								</li>
								<li>
									<label htmlFor="confirmpassword">
										<LangText
											ar="تأكيد كلمة المرور الجديدة"
											en="Confirm New Password"
										/>
									</label>
									<input
										required
										onChange={handleInputChange}
										type="password"
										name="confirmpassword"
										id="confirmpassword"
										minLength="8"
										placeholder={
											lang === "ar"
												? "أدخل تأكيد كلمة المرور"
												: "Confirm New Password"
										}
									/>
								</li>
							</ul>
						</div>

						<button
							type="submit"
							onClick={handleSubmit}
							className="w-[135px] h-[43.78px] p-[6.89px] rounded-[6.89px] flex justify-center items-center 
                  bg-gradient-to-bl from-[#4DBDF7] from-[6.97%]
                  to-[#9A9DFE] to-[71.22%]
                  text-white text-[21px] font-bold"
						>
							{lang === "ar" ? "تأكيد" : "Confirm"}
							{loading && (
								<>
									<span className="animate-spin flex justify-center items-center cursor-not-allowed">
										<LoaderCircle />
									</span>
								</>
							)}
						</button>
					</div>
				</form>
			)}
		</>
	);
}
