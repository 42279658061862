const UnKnown = (
  <svg
    width={60}
    height={60}
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.3981 81.2347C71.7196 68.9025 67.9438 59.9098 46.9395 59.9098C25.9358 59.9098 22.16 68.9025 21.4812 81.2347M59.735 34.3198C59.735 41.3863 54.0064 47.1148 46.94 47.1148C39.8735 47.1148 34.145 41.3863 34.145 34.3198C34.145 27.2533 39.8735 21.5248 46.94 21.5248C54.0064 21.5248 59.735 27.2533 59.735 34.3198ZM89.5899 47.1148C89.5899 70.6697 70.4949 89.7647 46.94 89.7647C23.3851 89.7647 4.29004 70.6697 4.29004 47.1148C4.29004 23.5599 23.3851 4.46484 46.94 4.46484C70.4949 4.46484 89.5899 23.5599 89.5899 47.1148Z"
      stroke="#333333"
      strokeWidth="8.08708"
      strokeLinecap="round"
    />
  </svg>
);

export { UnKnown };
