import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getRTLTextPluginStatus, setRTLTextPlugin } from "mapbox-gl";
import { gsap } from "gsap";
import { useMapContext } from "../../Hooks/mapContext";
import { Card } from "../RealStateInvestmentAi/RealStateInvestmentAiNew";

const apiKey = "AIzaSyC3LhU5__i5drYp2kZkZ5su50khcxh3sFU"; // Replace with your actual API key

const MyMap = () => {
  const {
    setSelectedPositionData,
    setMapPhotoData,
    searchData,
    mapPhotoData,
    setPhoto,
    items,
    setRemoveItem,
    setItems,
    update,
  } = useMapContext();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const circleLayerRef = useRef();
  const selectedParcels = useRef([]); // Store selected parcels coordinates
  const [loading, setLoading] = useState(false);
  const MAPBOX_TOKEN =
    "pk.eyJ1Ijoic3VoYWlsLWFwcCIsImEiOiJjazl0cjFrOWMxZ2p1M2huMW4xZTl2MzgzIn0.GMPVuCC_3EpJ4HZA8KZoPg"; // Replace with your Mapbox token

  useEffect(() => {
    setLoading(true);
    if (map.current) return; // Initialize map only once
    if (getRTLTextPluginStatus() === "unavailable") {
      setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Force plugin load
      );
    }
    mapboxgl.accessToken = MAPBOX_TOKEN;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "https://tiles.suhail.ai/riyadh/", // You can choose different styles
      center: [0, 0],
      zoom: 1.5,
      projection: "globe", // Display the map as a 3D globe
    });
    map.current.on("load", (e) => {
      console.log("loaded");
      setLoading(false);

      map.current.flyTo({
        center: { lat: 24.694466820974483, lng: 46.733376691538695 },
        essential: true,
        zoom: 14,
        speed: 0.7,
      });
    });

    map.current.on("style.load", () => {
      map.current.setFog({
        range: [-0.1, 0.1],
        color: "white",
        "horizon-blend": 0.03,
      });

      const layersToShow = [
        "subdivisions-fill",
        "parcels",
        "parcels-base",
        "neighborhoods",
      ]; // Example layers to show

      // Loop through all layers and hide those not in layersToShow
      map.current.getStyle().layers.forEach((layer) => {
        if (layersToShow.includes(layer.id)) {
          switch (layer.type) {
            case "fill":
              map.current.setPaintProperty(layer.id, "fill-opacity", 0);
              break;
            case "line":
              map.current.setPaintProperty(layer.id, "line-opacity", 0);
              break;
            case "symbol":
              map.current.setPaintProperty(layer.id, "text-opacity", 0);
              map.current.setPaintProperty(layer.id, "icon-opacity", 0);
              break;
            case "circle":
              map.current.setPaintProperty(layer.id, "circle-opacity", 0);
              break;
            case "raster":
              map.current.setPaintProperty(layer.id, "raster-opacity", 0);
              break;
            case "background":
              map.current.setPaintProperty(layer.id, "background-opacity", 1);
              break;
            default:
              break;
          }
        } else {
          // Ensure the selected layers are visible
          map.current.setLayoutProperty(layer.id, "visibility", "visible");
        }
      });
    });
    map.current.on("click", (event) => {
      console.log(event);
      let zoom = map.current.getZoom();
      console.log(zoom);
      if (zoom < 16) return;
      const { lng, lat } = event.lngLat;
      setMapPhotoData({ lng, lat, zoom });
      const features = map.current.queryRenderedFeatures(event.point, {});
      console.log(features, "the eee");
      function getAllUniqueProperties(featuresArray) {
        const uniqueProps = {};
        for (let feature of featuresArray) {
          if (feature.properties) {
            for (let key in feature.properties) {
              // If the key doesn't exist in uniqueProps, add it
              if (!uniqueProps.hasOwnProperty(key)) {
                uniqueProps[key] = feature.properties[key];
              }
            }
          }
        }

        return uniqueProps;
      }
      let data = getAllUniqueProperties(features);
      console.log(data);
      setSelectedPositionData(data);
      function light() {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ["parcels"], // Ensure "parcels" is the correct layer ID
        });
        if (features.length > 0) {
          const selectedParcel = features[0];
          const parcelCoordinates = selectedParcel.geometry.coordinates;

          // Toggle selection of the parcel
          toggleParcelSelection(parcelCoordinates, data);
          // setSelectedPositionData(selectedParcel.properties);
        }
      }
      light();
    });
  }, []); // Empty dependency array to run once
  const addItem = (selectedParcels) => {
    setItems([...selectedParcels]);
  };
  // Function to highlight or unhighlight selected parcels
  function toggleParcelSelection(coordinates, data) {
    // Check if the parcel is already selected
    const index = selectedParcels.current.findIndex(
      (coords) =>
        JSON.stringify(coords.coordinates) === JSON.stringify(coordinates)
    );

    if (index > -1) {
      // If the parcel is already selected, remove it
      selectedParcels.current.splice(index, 1);
      setRemoveItem(index);
    } else {
      // If the parcel is not selected, add it
      selectedParcels.current.push({ coordinates, data });
      addItem(selectedParcels.current);
    }

    // Update the highlighted parcels on the map without causing re-render
    updateHighlightedParcels();
  }

  // Function to update the highlighted parcels on the map
  function updateHighlightedParcels() {
    // Remove the existing highlight layer if present
    if (map.current.getLayer("highlighted-parcels")) {
      map.current.removeLayer("highlighted-parcels");
      map.current.removeSource("highlighted-parcels");
    }

    // Create a GeoJSON feature collection for all selected parcels
    const geojsonData = {
      type: "FeatureCollection",
      features: selectedParcels.current.map((coords) => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: coords.coordinates,
        },
      })),
    };

    // Add a new GeoJSON source for the highlighted parcels
    map.current.addSource("highlighted-parcels", {
      type: "geojson",
      data: geojsonData,
    });

    // Add a layer to style all highlighted parcels
    map.current.addLayer({
      id: "highlighted-parcels",
      type: "fill",
      source: "highlighted-parcels",
      paint: {
        "fill-color": "#ff0000", // Red color for highlighting
        "fill-opacity": 0.5,
      },
    });
  }

  useEffect(() => {
    if (mapPhotoData) {
      setPhoto(
        `https://maps.googleapis.com/maps/api/staticmap?center=${
          mapPhotoData?.lat
        },${
          mapPhotoData?.lng
        }&zoom=${16}&size=600x400&markers=color:red%7Clabel:A%7C${
          mapPhotoData?.lat
        },${mapPhotoData?.lng}&key=${apiKey}`
      );
      console.log(
        `https://maps.googleapis.com/maps/api/staticmap?center=${
          mapPhotoData?.lat
        },${
          mapPhotoData?.lng
        }&zoom=${16}&size=600x400&markers=color:red%7Clabel:A%7C${
          mapPhotoData?.lat
        },${mapPhotoData?.lng}&key=${apiKey}`
      );
    }
  }, [mapPhotoData]);
  useEffect(() => {
    if (searchData) console.log("this part updates", searchData);
    if (searchData)
      fetch(
        `https://el-samaloty-apis.vercel.app/api/search?num1=${searchData[0]}&num2=${searchData[1]}`,
        {
          method: "get",
        }
      )
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          if (e.content.length > 0) {
            const center = e.content[0]?.properties?.centroid;
            console.log(e, center);
            map.current.flyTo({
              center: { lat: center.y, lng: center.x },
              essential: true,
              zoom: 17,
              speed: 0.7,
            });
            // getData({ lat: center.y, lng: center.x });
          }
        });
  }, [update]);
  useEffect(() => {
    try {
      if (searchData[0]) {
        map.current.flyTo({
          center: { lat: searchData[1], lng: searchData[0] },
          essential: true,
          zoom: 17,
          speed: 0.7,
        });
      }
    } catch {}
  }, [searchData]);
  return (
    <div className="h-full w-full">
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "100%",
          display: loading ? "none" : "",
        }}
      />
      <div
        className="flex justify-center items-center text-4xl text-black"
        style={{
          width: "100%",
          height: "100%",
          display: !loading ? "none" : "",
        }}
      >
        loading map
      </div>
    </div>
  );
};

export default MyMap;
