import Header from "../components/Header";
import { useLang } from "../../Hooks/LangContext";
import { CircleCheck } from "lucide-react";
import { cheak } from "../../svgIcons/OurPlansIcon";
import UserApi from "../../apis/User";
import { useAuth } from "../../Hooks/authContext";
import { useSnackbar } from "../../Hooks/SnackBar";
import { useRefetch } from "../../Hooks/RefetchContext";
export default function OurPlans() {
	const { lang } = useLang();
	const { auth, account } = useAuth();
	const { openSnackbar } = useSnackbar();
	const { setRefetch } = useRefetch();
	const Premum = {
		Feature1:
			lang === "ar"
				? "عدد دراسات جدوى لا نهائي"
				: "Endless feasibility studies",
		Feature2:
			lang === "ar"
				? "عدد دراسات جدوى لا نهائي"
				: "Endless feasibility studies",
		Feature3:
			lang === "ar"
				? "عدد دراسات جدوى لا نهائي"
				: "Endless feasibility studies",
	};
	const Free = {
		Feature1:
			lang === "ar"
				? "عدد دراسات جدوى محدود"
				: "feasibility studies is Limited",
		Feature2:
			lang === "ar"
				? "عدد دراسات جدوى محدود"
				: "feasibility studies is Limited",
		Feature3:
			lang === "ar"
				? "عدد دراسات جدوى محدود"
				: "feasibility studies is Limited",
	};

	const subscribe = () => {
		UserApi.subscribe({ subscription: "Bronze" }, auth)
			.then((res) => {
				console.log(res);
				openSnackbar(
					lang === "ar" ? "تم الإشتراك بنجاح" : "Subscribed successfully"
				);
				setRefetch((prev) => !prev);
			})
			.catch((err) => {
				console.log(err);
				openSnackbar(
					lang === "ar"
						? "حدث خطأ أثناء الإشتراك"
						: "An error occurred while subscribing"
				);
			});
	};

	return (
		<div className="min-h-screen">
			<Header />
			<div className="flex justify-center  flex-col items-center">
				<div className="font-bold mb-20   text-center [&>p]:text-[30px]">
					<p>{lang === "ar" ? "احصل على" : "Get An"}</p>
					<h2
						className="text-[40px] md:text-[80px] font-bold text-transparent bg-clip-text
          bg-gradient-to-r from-[#4DBDF7]  via-[#00fff0] to-[#9a9dfe]"
					>
						{lang === "ar"
							? "دراسات جدوى لا نهائية"
							: "Endless feasibility studies"}
					</h2>
					<p>{lang === "ar" ? "مع خطتنا المدفوعة" : "With our paid plan"}</p>
				</div>
				<div
					className=" flex md:flex-row flex-col gap-5 lg:gap-20 
          mb-10
          
          [&>div]:h-full [&>div]:rounded-[10px] [&>div]:w-[350px] [&>div]:md:w-[400px] [&>div]:min-h-[490px] [&>div]:gap-10 [&>div]:py-10
          [&>div]:shadow-[0px_4px_10.3px_#00000040] [&>div]:flex [&>div]:flex-col [&>div]:items-center
          [&>div>h3]:font-bold [&>div>h3]:text-[30px]
        
          "
				>
					<div className="border duration-200 hover:scale-[1.01]  border-[#C7C7C7] text-center text-black font-bold ">
						<h2 className="text-[40px]">
							{lang === "ar" ? "الخطة المجانية" : "Free plan"}
						</h2>
						<ul className="flex flex-col gap-3">
							{Object.values(Free).map((e, i) => (
								<>
									<li
										className={`text-[20px] flex  items-center gap-2 ${
											lang === "ar" ? "flex-row" : "flex-row-reverse"
										}`}
										key={i}
									>
										{e}
										<CircleCheck />
									</li>
								</>
							))}
						</ul>
						<h3>{lang === "ar" ? "مجاناً" : "Free"}</h3>
					</div>
					<div className="OurPlansGrident duration-200 hover:scale-[1.01]  text-white">
						<h2 className="text-[40px]">
							{lang === "ar" ? "الخطة المميزة" : "Premium plan"}
						</h2>
						<ul className="flex flex-col gap-3">
							{Object.values(Premum).map((e, i) => (
								<>
									<li
										className={`text-[20px] flex  items-center gap-2 ${
											lang === "ar" ? "flex-row" : "flex-row-reverse"
										}`}
										key={i}
									>
										{e}
										{cheak}
									</li>
								</>
							))}
						</ul>
						<h3>{lang === "ar" ? "20 ريال / شهر" : "20 riyals / month"}</h3>
						<button
							onClick={subscribe}
							className="
            bg-white text-black text-[20px] font-bold
            w-[229px] h-[45px] flex justify-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] items-center p-[10px] rounded-[10px]"
						>
							{lang === "ar" ? "إشترك الآن" : "Subscribe now"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
