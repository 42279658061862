/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
// import { saveAs } from "file-saver";
import axios from "axios";
// import styles from "../pdf.css";

// import html2pdf from "html2pdf.js";
import html2pdf from "html2pdf.js";
import { useLocation, useNavigate } from "react-router";
import Pdf from "./Pdf";
import useKonvaWrapper from "../konva";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLang } from "../Hooks/LangContext";
import { usePopup } from "../Hooks/popup";
import useGeneratePDF from "../Hooks/download";
const DownloadPage = () => {
  const { generatePDF, isLoading, error } = useGeneratePDF();

  const location = useLocation();
  let dataObj = location?.state?.apiResponse;
  const images = location?.state?.images;
  const map = location?.state?.mapUrl;
  const prompts = location?.state?.prompts;
  const blockProps = location?.state?.blockProps;
  const { popup } = usePopup();
  const [cssText, setCssText] = useState("");
  const [loading, setloading] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  useEffect(() => {
    if (isGeneratingPdf) {
      popup.forced();
      popup.run(
        <div className="flex justify-center items-center w-full h-full text-3xl text-white">
          Generating pdf ...
        </div>
      );
    } else {
      popup.close();
    }
  }, [isGeneratingPdf]);
  useEffect(() => {
    setIsGeneratingPdf(isLoading);
  }, [isLoading]);
  const handleDownload = () => {
    generatePDF(dataObj, images, map);
    // const input = document.getElementById("pdfContainer22");
    // const opt = {
    //   margin: [0, 0, 0, 0],
    //   filename: "download.pdf",
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: {
    //     scale: 2,
    //     useCORS: true,
    //   },
    //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //   pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    // };

    // html2pdf()
    //   .from(input)
    //   .set(opt)
    //   .save()
    //   .finally(() => {
    //     setIsGeneratingPdf(false); // Reset the state back to normal
    //   });
  };
  const nav = useNavigate();
  const { lang } = useLang();
  return (
    <>
      <div className="w-screen overflow-x-hidden absolute left-0 top-0 bg-white">
        <div
          onClick={() => {
            nav(-1);
          }}
          className="cursor-pointer flex items-center gap-1 text-[#9995ee] font-semibold absolute left-20 top-20"
        >
          <MdKeyboardArrowLeft className="text-[25px]" />
          <p>{lang === "ar" ? "العوده" : "back"}</p>
        </div>
        <div className="px-[2%] md:px-[20%] py-[5%]">
          <div id="docsdo" className="">
            <HtmlComponent
              blockProps={blockProps}
              map={map}
              prompts={prompts}
              isGeneratingPdf={isGeneratingPdf}
              dataObj={dataObj}
              images={images}
            />
          </div>
        </div>
        <div className="w-full justify-center items-center flex pb-10 scale-150">
          <button
            className="bg-blue-500 flex justify-center items-center gap-2 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              handleDownload();
            }}
          >
            Download{" "}
            {isGeneratingPdf && (
              <span className="w-[20px] h-[20px] border-2 rounded-full border-white border-dashed animate-spin flex flex-row justify-center items-center"></span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export const HtmlComponent = ({
  dataObj,
  images,
  map,
  prompts,
  blockProps,
  isGeneratingPdf,
}) => {
  return (
    <>
      <FinancialStudyCard
        isGeneratingPdf={isGeneratingPdf}
        map={map}
        prompts={prompts}
        blockProps={blockProps}
        dataObj={dataObj}
        images={images}
      />
    </>
  );
};

const FinancialStudyCard = ({
  dataObj: dt,
  images,
  prompts,
  map,
  blockProps,
  isGeneratingPdf = false,
}) => {
  let propLocation = "";
  const location = useLocation();
  const navigate = useNavigate();

  const [dataObj, setDataObj] = useState(dt);
  if (
    !dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"].includes(
      "رقم القطعة"
    ) ||
    !dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"].includes(
      "رقم المخطط"
    )
  ) {
    const preLocation = `رقم القطعة ${blockProps?.blockId} رقم المخطط ${blockProps?.landId}`;
    console.log("pre location: ", preLocation);
    const tempLocation =
      dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"];
    propLocation = tempLocation;
    dataObj["propLocation"] = propLocation;
    console.log("propLocation: ", propLocation);
    dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"] =
      preLocation + ", " + tempLocation;
    console.log(
      "location data: ",
      dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"]["الموقع"]
    );
  }

  let developmentStandards = dataObj["تقرير_تحليل_الاستثمار"]["معايير_التطوير"];
  let table2 = dataObj["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"];
  let table3 = keepOnlyOneKey(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"],
    "الاستثمار_الكلي"
  );
  let table4 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"]["تكاليف_البناء"],
    ["التكاليف_الإضافية"]
  );
  let table5 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"]["تكلفة_شراء_الأرض"],
    []
  );
  let table6 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"][
      "توقعات_الإيرادات_من_البيع"
    ],
    []
  );
  let table7 = filterObjectByKeys(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"][
      "توقعات_الإيرادات_من_الإيجار"
    ],
    []
  );
  let table8 = keepOnlyOneKey(
    dataObj["تقرير_تحليل_الاستثمار"]["توقعات_التمويل"],
    "نتيجة_الاستثمار_الكلي"
  );
  const { popup } = usePopup();
  const updateInvesment = async (e, parentKey) => {
    console.log("updated", e);
    popup.forced();
    popup.run(
      <div className="w-full h-full text-white flex justify-center items-center text-2xl">
        Editing...
      </div>
    );
    // "/ar/investment-editor";
    console.log("last one", e);

    const inputKey =
      parentKey || parentKey !== ""
        ? `$[${parentKey}][${e.key}]: ${e.value}`
        : `$[${e.key}]: ${e.value}`; // Conditionally add parentKey

    const response = await fetch(
      `https://coral-app-8z265.ondigitalocean.app/ar/investment-editor`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          history: JSON.stringify(dataObj),
          input: inputKey,
        }),
      }
    );

    const d = await response.json();
    console.log(d);
    const images = location?.state?.images;
    const map = location?.state?.mapUrl;
    const prompts = location?.state?.prompts;
    const blockProps = location?.state?.blockProps;
    navigate("/convert", {
      state: {
        apiResponse: d,
        mapUrl: map,
        images: images,
        prompts,
        blockProps: blockProps,
      },
    });
    setDataObj(d);
    popup.close();
  };

  return (
    <>
      <Pdf
        blockProps={blockProps}
        propLocation={propLocation}
        dataObj={dataObj}
        images={images}
        map={map}
        prompts={prompts}
        isGeneratingPdf={isGeneratingPdf}
        tables={
          <>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table2,
                "تفاصيل عن المشروع",
                (e) => updateInvesment(e, "تفاصيل_المشروع"), // Passing parent key
                isGeneratingPdf
              )}
              {RenderTable(
                developmentStandards,
                "النسب والمعايير",
                (e) => updateInvesment(e, "معايير_التطوير"), // Passing parent key
                isGeneratingPdf
              )}
            </div>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table5,
                "تكلفة شراء الأرض",
                (e) => updateInvesment(e, "تكلفة_شراء_الأرض"), // Passing parent key
                isGeneratingPdf
              )}
              {RenderTable(
                table4,
                "تكاليف البناء",
                (e) => updateInvesment(e, "تكاليف_البناء"), // Passing parent key
                isGeneratingPdf
              )}
              {RenderTable(
                table3,
                "توقعات الإستثمار",
                (e) => updateInvesment(e, ""), // Passing parent key
                isGeneratingPdf
              )}
            </div>
            <div className={isGeneratingPdf ? "element-to-print" : ""}>
              {RenderTable(
                table6,
                "توقعات الإيرادات من البيع",
                (e) => updateInvesment(e, "توقعات_الإيرادات_من_البيع"), // Passing parent key
                isGeneratingPdf
              )}
              {RenderTable(
                table7,
                "توقعات الإيرادات من الإيجار",
                (e) => updateInvesment(e, "توقعات_الإيرادات_من_الإيجار"), // Passing parent key
                isGeneratingPdf
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default DownloadPage;

const convertToArabicNumbers = (num) => {
  return num;
};
const FinancialStudy = ({ dataObj }) => {
  const [allNums, setAllNums] = useState(extractAll(dataObj));

  // Example function to format numbers into Arabic numerals, modify as necessary

  // Determine the unit based on the label
  const determineUnit = (label) => {
    if (label.includes("مساحة")) return "متر مربع";
    if (label.includes("حجم")) return "متر مكعب";
    if (label.includes("الوحدات")) return " عدد الوحدات";
    // if (label.includes('نتيجة') || label.includes('نسبة')) return '%';
    if (label.includes("تكلفة") || label.includes("التكلفة"))
      return "ريال سعودي";
    return ""; // Default return if none of the conditions are met
  };

  return (
    <div className="financial-study-container">
      <div class="card-header">
        <h1 class="card-title">دراسة مختصرة | {dataObj["العنوان"]}</h1>
      </div>

      {/* Dynamic table for numbers */}
      <table style={{ direction: "rtl" }} className="financial-study-table">
        <tbody>
          {allNums.map((item) => (
            <tr key={item.label}>
              <td>{item.label.replace(/_/g, " ")}</td>
              <td>{convertToArabicNumbers(item.number)}</td>
              <td>{determineUnit(item.label)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="footer w-[200px]">
        <img
          src="https://res.cloudinary.com/dfbtwwa8p/image/upload/v1721790549/kotxkxycpfeeocxnirg2.png"
          alt="Logo"
          className="logo"
        />
        {/* <span>تكامل المتقدمة</span> */}
      </div>
    </div>
  );
};

// function DevelopmentStandards({ data }) {
//   // A function to determine if a value is an object that should be displayed as a table
const isObject = (value) => {
  return value && typeof value === "object" && !Array.isArray(value);
};

// Render tables recursively
const RenderTable = (
  obj,
  title = "",
  updateFunction = () => {},
  isGeneratingPdf
) => {
  const [editableValue, setEditableValue] = useState(null);
  const handleDoubleClick = (key, value) => {
    setEditableValue({ key, value });
  };

  const handleInputChange = (e) => {
    setEditableValue((prev) => ({ ...prev, value: e.target.value }));
  };

  const handleInputBlur = () => {
    if (editableValue && editableValue.value !== obj[editableValue.key]) {
      console.log("Updated value:", editableValue);
      updateFunction(editableValue);
    }
    setTimeout(() => {
      setEditableValue(null);
    }, 500);
  };

  return (
    <div className="card-section avoid-break flex flex-col justify-center items-center">
      {title && <h2 className="intro">{formatKey(title)}</h2>}
      <div
        style={{
          width: isGeneratingPdf ? "100%" : "100%",
          fontSize: isGeneratingPdf ? "14px" : "20px",
        }}
        className="table-container"
      >
        <table style={{ direction: "rtl" }} className="table">
          <thead>
            <tr>
              <th>الوصف</th>
              <th>القيمة</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(obj).map(([key, value]) => {
              if (isObject(value)) {
                // Recursively render nested tables with a title derived from the key
                return RenderTable(value, formatKey(key));
              } else {
                // Render a simple table row for key-value pairs
                return (
                  <tr key={key}>
                    <td>{formatKey(key)}</td>
                    <td
                      onDoubleClick={() => handleDoubleClick(key, value)}
                      key={key}
                    >
                      {!isGeneratingPdf &&
                      editableValue &&
                      editableValue.key === key ? (
                        <input
                          className="tdInput"
                          type="text"
                          style={{ width: "80%", outline: "none" }}
                          value={editableValue.value}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoFocus
                        />
                      ) : (
                        formatKey(convertToArabicNumbers(value))
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

//   // Helper function to format keys

//   return renderTable(data);
// }

const formatKey = (key) => {
  return key
    .split("_")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(" ");
};

// Helper function to translate expressions into more readable format
const formatValue = (value) => {
  if (typeof value === "string") {
    return value
      .replace(/=/g, " = ")
      .replace(/\*/g, " × ")
      .replace(/\//g, " ÷ ");
  }
  return value;
};

function filterObjectByKeys(obj, keysToRemove) {
  const filteredObject = { ...obj };
  keysToRemove.forEach((key) => {
    delete filteredObject[key];
  });
  return filteredObject;
}

function keepOnlyOneKey(obj, keyToKeep) {
  return obj.hasOwnProperty(keyToKeep) ? { [keyToKeep]: obj[keyToKeep] } : {};
}

function extractAll(data, result = []) {
  for (const key in data) {
    if (typeof data[key] === "object") {
      extractAll(data[key], result);
    } else if (typeof data[key] === "string") {
      const matches = data[key].match(/\d+(\.\d+)?/g); // Regex to match integers and decimals
      if (matches) {
        matches.forEach((match) => {
          result.push({ label: key, number: parseFloat(match) });
        });
      }
    }
  }
  return result;
}
