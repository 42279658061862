import { createContext, useState, useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLang } from "./LangContext";

const SnackbarContext = createContext();

export const useSnackbar = () => {
	return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
	const { lang } = useLang();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const openSnackbar = (message) => {
		setSnackbarMessage(message);
		setSnackbarOpen(true);
		setTimeout(() => {
			closeSnackbar();
		}, 3000);
	};

	const closeSnackbar = () => {
		setSnackbarOpen(false);
		setSnackbarMessage("");
	};

	const variants = {
		initial: {
			x: lang === "ar" ? "-100%" : "100%",
			opacity: 0,
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				type: "spring",
				stiffness: 100,
				damping: 20,
			},
		},
		exit: {
			x: lang === "ar" ? "-100%" : "100%",
			opacity: 0,
			transition: {
				type: "spring",
				stiffness: 100,
				damping: 20,
			},
		},
	};

	return (
		<SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
			{children}
			<AnimatePresence>
				{snackbarOpen && (
					<motion.div
						className={`fixed z-[99999] top-20 ${
							lang === "ar" ? "left-10" : "right-10"
						} w-fit mx-auto rounded-lg bg-[#9d9dff] text-white text-lg font-semibold p-4`}
						initial="initial"
						animate="animate"
						exit="exit"
						variants={variants}
					>
						<p>{snackbarMessage}</p>
					</motion.div>
				)}
			</AnimatePresence>
		</SnackbarContext.Provider>
	);
};
