import { MapPin, CalendarFold, Trash2, ArrowDownToLine } from "lucide-react";
import { useLang } from "../../../Hooks/LangContext";
import { Spot, Area } from "../../../svgIcons/FeasibilityStudiesIcons";
import { useNavigate } from "react-router-dom";
import useGeneratePDF from "../../../Hooks/download";
export function Card({ data }) {
  const { lang } = useLang();
  const navigate = useNavigate();
  const { generatePDF, isLoading, error } = useGeneratePDF();

  return (
    <article
      className="bg-[#f4f4f4] duration-200 hover:scale-[1.01] 
    shadow-[4px_4px_17.5px_0px_#00000040] w-[368px] h-[490px] 
    overflow-hidden rounded-[10px] "
    >
      <div className="w-full">
        <img
          src={data?.images[0] ? data?.images[0] : "/images/cardimg.png"}
          className="object-cover w-[368px] h-[261px]  rounded-[10px]"
          alt=""
        />
      </div>
      <h2 className="text-[#311616] h-[60px] flex items-center justify-center my-2 font-bold text-[20px] text-center">
        {data?.data["العنوان"]}
      </h2>
      <div className="bg-[#ebebeb] h-full rounded-b-[10px]">
        <ul className="[&>li>p]:text-[15px] [&>li>p]:font-bold [&>li]:flex [&>li]:justify-end flex flex-col gap-1 pt-2 [&>li]:items-center px-2   [&>li]:gap-2    text-right ">
          <li>
            <p>
              {"الموقع : " +
                data?.data["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"][
                  "الموقع"
                ]}{" "}
            </p>
            <MapPin size={15} />
          </li>
          {/* <li>
						<p>المساحة : 527 م²</p>
						{Area}
					</li> */}
          <li>
            <p>
              {"نوع المشروع : " +
                data?.data["تقرير_تحليل_الاستثمار"]["تفاصيل_المشروع"][
                  "نوع_المشروع"
                ]}
            </p>
            {Spot}
          </li>
          <li>
            <p>{"تم إنشاء الدراسة في : " + data?.createdAt.split("T")[0]}</p>
            <CalendarFold size={15} />
          </li>
        </ul>
        <div className="flex items-center mt-4 justify-around">
          <button className="p-[5px] duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] rounded-[5.09px] bg-[#BC0000] text-white">
            <Trash2 size={17} />
          </button>
          <button
            onClick={() => {
              console.log(data);
              generatePDF(data.data, data.images, data.mapImage);
            }}
            className="bg-[#FFFFFF] duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] p-[5px] rounded-[5.09px]"
          >
            <ArrowDownToLine size={17} />
          </button>
          <button
            onClick={() => {
              navigate("/convert", {
                state: {
                  apiResponse: data.data,
                  mapUrl: data.mapImage,
                  images: data.images,
                  blockProps: {
                    blockId: "",
                    landId: "",
                  },
                },
              });
            }}
            className="bg-gradient-to-t font-bold rounded-[5.09px]
              flex justify-center items-center duration-200 hover:shadow-[4px_4px_17.5px_0px_#00000040] w-[254px] h-[28px] p-[5.09px]
              from-[#4DBDF7] to-[#9A9DFE]"
          >
            {lang === "ar" ? "عرض تفاصيل المشروع" : "View project details"}
          </button>
        </div>
      </div>
    </article>
  );
}

export function CardSkelaton() {
  return (
    <div
      role="status"
      className="w-[368px] h-[490px]  border border-gray-200  shadow animate-pulse rounded-[10px] dark:border-gray-700"
    >
      <div className="flex items-center rounded-[10px] justify-center w-[368px] h-[261px] mb-4 bg-gray-300  dark:bg-gray-700"></div>
      <div className="bg-white w-full h-[60px]">
        <div className="h-2.5 mx-auto bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
        <div className="h-2 mx-auto bg-gray-200 rounded-full dark:bg-gray-700 w-20 mb-2.5" />
      </div>
      <div className="bg-[#ebebeb] flex flex-col gap-4 h-[150px] rounded-b-[10px] ">
        <div className="flex  [&>div]:flex-row [&>div]:justify-end [&>div]:gap-5 [&>div]:items-center justify-start px-5  [&>div]:flex flex-col  gap-4 py-2 ">
          <div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5 w-32 " />
            <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700  w-5 " />
          </div>
          <div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-40 " />
            <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700 w-5 " />
          </div>
          <div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-52 mb-2" />
            <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700 w-5 " />
          </div>
        </div>
        <div className="flex items-center justify-around">
          <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700 w-5 " />
          <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700 w-5 " />
          <div className="h-5 bg-gray-200 rounded-[5px] dark:bg-gray-700 w-40  " />
        </div>
      </div>

      <span className="sr-only">Loading...</span>
    </div>
  );
}
