import axiosClient from "./axiosClient";

const login = async (data) => axiosClient.post("auth/login", data);
const signup = async (data) => axiosClient.post("auth/signup", data);
const updateUserInfo = async (token, data) => axiosClient.post("user/updateUser", data, { headers: { token: token } });
const resetpassword = async (token, data) => axiosClient.post("auth/resetpassword", data, { headers: { token: token } });

const authApi = {
  login,
  signup,
  updateUserInfo,
  resetpassword
};

export default authApi;