import { Link } from "react-router-dom";
import { useLang } from "../../Hooks/LangContext";
import Header from "../components/Header";
import { Card, CardSkelaton } from "./components/Card";
import StudyCasesApi from "../../apis/StudyCases";
import { useAuth } from "../../Hooks/authContext";
import { useEffect, useState } from "react";
export default function FeasibilityStudies() {
  const { lang } = useLang();
  const { auth } = useAuth();
  const [studies, setStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const getAllCases = () => {
    setLoading(true);
    StudyCasesApi.getAllCases(auth)
      .then((res) => {
        console.log(res.data.studyCases);
        setStudies(res.data.studyCases);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErr(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCases();
  }, []);
  return (
    <div className="min-h-screen">
      <Header />
      <div className="flex flex-col gap-10 mt-20 w-[95%] mx-auto">
        <div
          className={`${
            lang === "ar" ? "flex-row" : "flex-row-reverse"
          } font-bold items-center  flex justify-between `}
        >
          <Link
            to={"/investmentAi"}
            className="md:text-[20px] bg-gradient-to-t from-[#4DBDF7] to-[#9A9DFE] text-white rounded-[10px] p-[10.6px]"
          >
            {lang === "ar" ? "إنشاء دراسة جديدة" : "Create a new study"}
          </Link>
          <h2 className="md:text-[28px]">
            {lang === "ar"
              ? "دراسات الجدوى الخاصة بك"
              : "Your feasibility studies"}
          </h2>
        </div>
        <div className="flex gap-10 flex-wrap my-2 justify-center">
          {loading && (
            <>
              <CardSkelaton />
              <CardSkelaton />
              <CardSkelaton />
              <CardSkelaton />
              <CardSkelaton />
              <CardSkelaton />
            </>
          )}
          {!loading && (
            <>
              {studies.map((study) => (
                <Card data={study} key={study._id} />
              ))}
            </>
          )}
          {err && (
            <div
              className={`text-red-600 ${
                lang === "ar" ? "text-[60px]" : "text-[50px]"
              }  font-bold`}
            >
              {lang === "ar" ? "لقد حدث خطأ ما" : "something went wrong"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
