const cheak = (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.85019 9.1201C3.70423 8.46262 3.72665 7.77894 3.91535 7.13244C4.10405 6.48593 4.45294 5.89754 4.92966 5.42182C5.40638 4.9461 5.9955 4.59844 6.6424 4.41109C7.2893 4.22374 7.97303 4.20276 8.63019 4.3501C8.9919 3.7844 9.4902 3.31886 10.0791 2.99638C10.6681 2.67391 11.3287 2.50488 12.0002 2.50488C12.6716 2.50488 13.3323 2.67391 13.9212 2.99638C14.5102 3.31886 15.0085 3.7844 15.3702 4.3501C16.0284 4.20212 16.7133 4.22301 17.3612 4.41081C18.0091 4.59862 18.599 4.94724 19.076 5.42425C19.5531 5.90126 19.9017 6.49117 20.0895 7.1391C20.2773 7.78703 20.2982 8.47193 20.1502 9.1301C20.7159 9.49181 21.1814 9.9901 21.5039 10.579C21.8264 11.168 21.9954 11.8286 21.9954 12.5001C21.9954 13.1715 21.8264 13.8322 21.5039 14.4211C21.1814 15.0101 20.7159 15.5084 20.1502 15.8701C20.2975 16.5273 20.2765 17.211 20.0892 17.8579C19.9018 18.5048 19.5542 19.0939 19.0785 19.5706C18.6027 20.0473 18.0144 20.3962 17.3679 20.5849C16.7213 20.7736 16.0377 20.7961 15.3802 20.6501C15.019 21.218 14.5203 21.6855 13.9303 22.0094C13.3404 22.3333 12.6782 22.5032 12.0052 22.5032C11.3322 22.5032 10.67 22.3333 10.0801 22.0094C9.49011 21.6855 8.99143 21.218 8.63019 20.6501C7.97303 20.7974 7.2893 20.7765 6.6424 20.5891C5.9955 20.4018 5.40638 20.0541 4.92966 19.5784C4.45294 19.1027 4.10405 18.5143 3.91535 17.8678C3.72665 17.2213 3.70423 16.5376 3.85019 15.8801C3.28015 15.5193 2.81061 15.0203 2.48524 14.4293C2.15988 13.8384 1.98926 13.1747 1.98926 12.5001C1.98926 11.8255 2.15988 11.1618 2.48524 10.5709C2.81061 9.97992 3.28015 9.48085 3.85019 9.1201Z"
      fill="url(#paint0_linear_833_210)"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.5L11 14.5L15 10.5"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_833_210"
        x1="3.5"
        y1="5.5"
        x2="11.9923"
        y2="22.5032"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF170" />
        <stop offset={1} stopColor="#FFC700" />
      </linearGradient>
    </defs>
  </svg>
);

export { cheak };
