import { useEffect, useRef, useState } from "react";
import { useMapContext } from "../../Hooks/mapContext";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { Map, Command } from "lucide-react";

export const MAP_TOKEN =
  "pk.eyJ1Ijoic3VoYWlsLWFwcCIsImEiOiJjazl0cjFrOWMxZ2p1M2huMW4xZTl2MzgzIn0.GMPVuCC_3EpJ4HZA8KZoPg";
export const getPlacesFromMap = async ({ place }) => {
  if (place.length > 2) {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=${MAP_TOKEN}&country=sa&language=ar&types=poi,place`
    );
    return response.data;
  }
  return null;
};

export const MapSearch = ({ searchMethode, setSearchMethode, isMobile }) => {
  const { setSearchData, searchData, updateSearch } = useMapContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [center, setCenter] = useState([]);
  const [placeName, setPlaceName] = useState("");
  const [clickedOut, SetClickedOut] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        SetClickedOut(true);
      } else {
        SetClickedOut(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchInput = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (searchMethode === "address" && query.length > 2) {
      const data = await getPlacesFromMap({ place: query });
      if (data && data.features) {
        setSearchResults(data.features); // Store the API response
        console.log(data.features);
      }
    }
  };

  return (
    <div className="flex lg:gap-2 flex-col-reverse gap-10 lg:flex-row  grow items-center md:pt-0 pt-10 font-semibold">
      <button
        disabled={!searchQuery && searchMethode === "address"}
        style={
          !searchQuery && searchMethode === "address"
            ? { cursor: "not-allowed" }
            : { cursor: "pointer" }
        }
        onClick={() => {
          if (searchMethode === "address") {
            setSearchData(center);
          } else {
            const updatedData = {
              num1: searchData.num1 || 0,
              num2: searchData.num2 || 0,
            };
            setSearchData(Object.values(updatedData));
            console.log(searchData);
          }
          updateSearch();
        }}
        className="hover:text-[#9A9DFE] hover:bg-white hover:border-[#9A9DFE] duration-300 text-[#FFFFFF] bg-[#9A9DFE] border-2 border-white  rounded-[20px] w-[180px] h-[40px] flex justify-center items-center"
      >
        بحث
      </button>
      <section className="lg:grow w-full flex  justify-center items-center">
        {searchMethode !== "address" ? (
          <div className="flex  md:flex-row flex-col gap-8  px-10">
            <input
              onChange={(e) => {
                const { name, value } = e.target;
                setSearchData((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));
              }}
              className="bg-[#F0F0F0] w-full 
        rounded-[21px] placeholder:text-right py-2 px-5
         focus:outline-none text-right border border-[#CCCCCC]
         [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="رقم القطعة"
              type="text"
              name="num2"
            />
            <input
              onChange={(e) => {
                const { name, value } = e.target;
                setSearchData((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));
              }}
              className="bg-[#F0F0F0] w-full 
        rounded-[21px] placeholder:text-right py-2 px-5
         focus:outline-none text-right border border-[#CCCCCC]
         [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="رقم المخطط"
              type="text"
              name="num1"
            />
            <select
              className="bg-[#F0F0F0]  
        rounded-[21px] placeholder:text-right py-2  flex justify-center items-center w-full 
         focus:outline-none text-right border border-[#CCCCCC]
         "
              dir="rtl"
              name=""
              id=""
            >
              <option>الرياض</option>
              <option>جدة</option>
              <option>مكة</option>
            </select>
          </div>
        ) : (
          <div
            ref={dropdownRef}
            className="relative grow flex justify-center items-center"
          >
            <input
              className="bg-[#F0F0F0] w-[70%] mx-auto  
        rounded-[21px] placeholder:text-right py-2 px-1
         focus:outline-none text-right border border-[#CCCCCC]"
              placeholder="العنوان"
              type="search"
              name="searchQuery"
              value={placeName || searchQuery}
              onClick={() => {
                setPlaceName("");
              }}
              onChange={handleSearchInput}
              id="search"
            />
            {searchQuery.length > 0 && searchResults.length > 0 && (
              <div
                className={`${placeName ? "hidden" : "block"}
                ${clickedOut ? "hidden" : "block"}
                absolute left-1/2 w-[65%] bg-white h-[230px] shadow-md -translate-x-1/2  px-2 rounded-b-3xl top-[45px]  z-[1000]
              `}
              >
                <ul
                  className={` h-[200px]
                [&::-webkit-scrollbar]:w-1
              rounded-b-3xl
              bg-white
               [&::-webkit-scrollbar-track]:rounded-full
               [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:bg-gray-300
            [&::-webkit-scrollbar-thumb]:h-[180px]
                 overflow-y-auto  mt-2   p-2`}
                >
                  {searchResults.map((result, index) => (
                    <li
                      onClick={() => {
                        setCenter(result.center);
                        setPlaceName(result.place_name_ar);
                      }}
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {result.place_name_ar}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </section>
      <section
        className={`flex sm:flex-row flex-col-reverse justify-end  ${
          searchMethode === "adress" && "grow"
        } gap-4  items-center`}
      >
        <div className="flex gap-8  md:flex-row flex-col items-center">
          <label
            onClick={() => setSearchMethode("numberplans")}
            className={`flex items-center  text-nowrap font-bold gap-2  ${
              searchMethode !== "address" ? "text-[#9A9DFE]" : "text-black"
            }`}
          >
            <p>رقم المخطط</p>
            <div className="w-[20px]">
              <img
                src={
                  searchMethode !== "address"
                    ? "/plannumberBlue.webp"
                    : "/plannumberBlack.webp"
                }
                className="object-cover"
                alt=""
              />
            </div>
            <input
              name="searchMethode"
              onClick={() => setSearchMethode("numberplans")}
              type="radio"
            />
          </label>

          <label
            onClick={() => setSearchMethode("address")}
            className={`flex items-center  text-nowrap font-bold  gap-2 ${
              searchMethode !== "address" ? "text-black" : "text-[#9A9DFE]"
            }`}
          >
            <p>العنوان</p>
            <Map />
            <input
              defaultChecked
              name="searchMethode"
              onClick={() => setSearchMethode("address")}
              type="radio"
            />
          </label>
        </div>
        <h2 className="font-bold text-nowrap text-[22px]">ابحث بواسطة</h2>
      </section>
    </div>
  );
};
