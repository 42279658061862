import { useLang } from "../Hooks/LangContext";
export const LangText = ({ ar = "", en = "", className }) => {
  const { lang } = useLang("ar");
  return (
    <div
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`flex   ${className} ${
        lang === "ar" ? " text-right" : "text-left"
      } `}
    >
      {lang === "ar" ? ar : en}
    </div>
  );
};
